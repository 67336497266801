import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import DetailImage from "../assets/detail-image.jpg";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
export default function Detail() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // Retrieve individual parameters
  const title = searchParams.get("title");
  const image = searchParams.get("image");
  const desc = searchParams.get("desc");
  const initialBorderColor = "#bec4c7";

  const [borderColors, setBorderColors] = useState(
    Array(50).fill(initialBorderColor)
  );

  const [prevTitle, setPrevTitle] = useState("");

  useEffect(() => {
    const storedTitle = localStorage.getItem("prevTitle");
    if (storedTitle) {
      setPrevTitle(storedTitle);
    }

    const storedBorderColors = localStorage.getItem("borderColors");
    if (storedBorderColors) {
      setBorderColors(JSON.parse(storedBorderColors));
    }
  }, []);

  const handleClick = (title, index) => {
    const newBorderColors = borderColors.map((color, idx) => {
      window.scrollTo(0, 0);
      return idx === index ? "#79ba44" : "#bec4c7"; // Change to green color for the clicked index
    });
    setBorderColors(newBorderColors);
    setPrevTitle(title);
    localStorage.setItem("prevTitle", title);
    localStorage.setItem("borderColors", JSON.stringify(newBorderColors));
  };


  return (
    <div className="font-poppins ">
      <Header />

      <div className="bg-[#F5F5F5] min-h-screen pt-4 md:pt-20">
        <div className="bg-white mx-4 my-6 md:mx-20  p-4 md:p-12">
          <p className="text-2xl md:text-4xl lg:text-5xl  mb-4 text-[#79ba44]">
            {title}
          </p>

          <div className="flex flex-col md:flex-row pt-8">
            <div className="md:w-1/3 pr-0 md:pr-8">
              <img
                src={image}
                className="w-full h-[400]"
                alt="Water base ink 
                            "
              />
            </div>
            <div className="md:w-1/2 md:ml-4 mt-4 md:mt-0">
              <p className="text-base md:text-lg lg:text-xl">{desc} </p>
            </div>
          </div>
        </div>
        {title == "Water Based Inks" ? (
          <div className="bg-white my-16 md:my-32 py-16 px-4 md:px-12 mx-4 md:mx-20">
            <p className="text-[20px] md:text-[24px] pb-8">
              {" "}
              6 Products Recommendations
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8  ">
              <Link onClick={handleClick} to={`/detail/product?title=Table printing series `}>
                <div
                  className="min-h-[220px] w-full border border-[#bec4c7] py-[15px] px-[15px]"
                  style={{ borderColor: borderColors[0] }}
                  onClick={() => handleClick("Table printing series", 0)}
                >
                  <p className="text-[18px] font-700 text-[black] font-700">
                    Table printing series
                  </p>
                  <p className="text-[14px] pt-2 text-[#333333] leading-[26px]">
                    A range of inks designed for high-quality printing in
                    tabular formats, ensuring clarity and precision in printed
                    documents.
                  </p>
                </div>
              </Link>
              <Link onClick={handleClick} to={`/detail/product?title=Machine grade series`}>
                <div
                  className="min-h-[220px] w-full  border border-[#bec4c7]  py-[15px] px-[15px]"
                  style={{ borderColor: borderColors[1] }}
                  onClick={() => handleClick("Machine grade series", 1)}
                >
                  <p className="text-[18px]  font-700 text-[black] font-700">
                    Machine grade series{" "}
                  </p>
                  <p className="text-[14px] pt-2  text-[#333333] leading-[26px] ">
                    {" "}
                    Machine grade series categorizes machinery based on
                    performance, capabilities, and specifications for specific
                    tasks or industries.
                  </p>
                </div>
              </Link>
              <Link onClick={handleClick} to={`/detail/product?title=Pu`}>
                <div
                  className="min-h-[220px] w-full  border border-[#bec4c7]  py-[15px] px-[15px]"
                  style={{ borderColor: borderColors[2] }}
                  onClick={() => handleClick("Pu", 2)}
                >
                  <p className="text-[18px]  font-700 text-[black] font-700 ">
                    Hybrid series ( PU & Acrylic){" "}
                  </p>
                  <p className="text-[14px] pt-2  text-[#333333] leading-[26px] ">
                    {" "}
                    Hybrid & PU based water-based coatings blend hybrid and
                    polyurethane components for eco-friendly and durable
                    applications.
                  </p>
                </div>
              </Link>
              <Link onClick={handleClick} to={`/detail/product?title=Non Blocking Series`}>
                <div
                  className="min-h-[220px] w-full  border border-[#bec4c7]  py-[15px] px-[15px]"
                  style={{ borderColor: borderColors[3] }}
                  onClick={() => handleClick("Non", 3)}
                >
                  <p className="text-[18px]  font-700 text-[black] font-700 ">
                    Non-blocking series{" "}
                  </p>
                  <p className="text-[14px] pt-2  text-[#333333] leading-[26px] ">
                    {" "}
                    Inks that do not cause halting or waiting during printing
                    processes, ensuring continuous and efficient production.
                  </p>
                </div>{" "}
              </Link>
              <Link onClick={handleClick} to={`/detail/product?title=Water based special effects`}>
                <div
                  className="min-h-[220px] w-full  border border-[#bec4c7] py-[15px] px-[15px]"
                  style={{ borderColor: borderColors[4] }}
                  onClick={() => handleClick("Water based special effects", 4)}
                >
                  <p className="text-[18px]  font-700 text-[black] font-700 ">
                    Water based special effects
                  </p>
                  <p className="text-[14px] pt-2  text-[#333333] leading-[26px] ">
                    {" "}
                    Ink formulated with water as a base medium, designed to
                    create unique visual or tactile effects in printing
                    applications.
                  </p>
                </div>
              </Link>
              <Link onClick={handleClick} to={`/detail/product?title=Water based pigments`}>
                <div
                  className="h-[220px] w-full  border border-[#bec4c7]  py-[15px] px-[15px]"
                  style={{ borderColor: borderColors[5] }}
                  onClick={() => handleClick("Water based pigments", 5)}
                >
                  <p className="text-[18px]  font-700 text-[black] font-700">
                    Water based pigments
                  </p>
                  <p className="text-[14px] pt-2  text-[#333333] leading-[26px] ">
                    {" "}
                    Ink using water as a solvent, containing pigments for
                    coloration in various printing applications, known for
                    environmental friendliness and vivid hues
                  </p>
                </div>
              </Link>
            </div>
          </div>
        ) : title === "Silicone Inks" ? (
          <div className="bg-white my-16 md:my-32 py-16 px-4 md:px-12 mx-4 md:mx-20">
            <p className="text-[20px] md:text-[24px] pb-8">
              {" "}
              10 Products Recommendations
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8  ">
              <Link onClick={handleClick} to={`/detail/product?title=Transfer silicone`}>
                <div
                  className="min-h-[220px] w-full  border border-[#bec4c7] py-[15px] px-[15px]"
                  style={{ borderColor: borderColors[6] }}
                  onClick={() => handleClick("Transfer silicone", 6)}
                >
                  <p className="text-[18px]  font-700 text-[black] font-700 ">
                    Transfer silicone
                  </p>
                  <p className="text-[14px] pt-2  text-[#333333] leading-[26px] ">
                    {" "}
                    Ink formulated with silicone for heat transfer printing,
                    enabling durable and flexible designs on various substrates.
                  </p>
                </div>
              </Link>
              <Link onClick={handleClick} to={`/detail/product?title=Transfer silicone`}>
                <div
                  className="min-h-[220px] w-full  border border-[#bec4c7]  py-[15px] px-[15px]"
                  style={{ borderColor: borderColors[7] }}
                  onClick={() => handleClick("Embossing silicone", 7)}
                >
                  <p className="text-[18px]  font-700 text-[black] font-700">
                    Embossing silicone
                  </p>
                  <p className="text-[14px] pt-2  text-[#333333] leading-[26px] ">
                    {" "}
                    Ink designed for creating raised or embossed effects on
                    printed surfaces using silicone-based formulations.
                  </p>
                </div>{" "}
              </Link>
              <div
                className="min-h-[220px] w-full  border border-[#bec4c7]  py-[15px] px-[15px]"
                style={{ borderColor: borderColors[8] }}
                onClick={() => handleClick("Glossy silicone", 8)}
              >
                <p className="text-[18px]  font-700 text-[black] font-700 ">
                  Glossy silicone
                </p>
                <p className="text-[14px] pt-2  text-[#333333] leading-[26px] ">
                  {" "}
                  Ink containing silicone for printing applications, producing a
                  glossy finish on surfaces, enhancing visual appeal and
                  durability.
                </p>
              </div>{" "}
              <div
                className="min-h-[220px] w-full  border border-[#bec4c7]  py-[15px] px-[15px]"
                style={{ borderColor: borderColors[9] }}
                onClick={() => handleClick("Matte silicone", 9)}
              >
                <p className="text-[18px]  font-700 text-[black] font-700 ">
                  Matte silicone
                </p>
                <p className="text-[14px] pt-2  text-[#333333] leading-[26px] ">
                  {" "}
                  Ink formulated with silicone for printing applications,
                  providing a non-shiny, matte finish on surfaces for a subdued
                  aesthetic.
                </p>
              </div>{" "}
              <Link onClick={handleClick} to={`/detail/product?title=Anti slip silicone`}>
                <div
                  className="min-h-[220px] w-full  border border-[#bec4c7] py-[15px] px-[15px]"
                  style={{ borderColor: borderColors[10] }}
                  onClick={() => handleClick("Anti slip silicone", 10)}
                >
                  <p className="text-[18px]  font-700 text-[black] font-700 ">
                    Anti slip silicone
                  </p>
                  <p className="text-[14px] pt-2  text-[#333333] leading-[26px] ">
                    {" "}
                    Ink containing silicone designed to create textured
                    surfaces, enhancing grip and preventing slippage in printed
                    materials.
                  </p>
                </div>
              </Link>
              <div
                className="min-h-[220px] w-full  border border-[#bec4c7]  py-[15px] px-[15px]"
                style={{ borderColor: borderColors[11] }}
                onClick={() => handleClick("Dipping silicone", 11)}
              >
                <p className="text-[18px]  font-700 text-[black] font-700">
                  Dipping silicone
                </p>
                <p className="text-[14px] pt-2  text-[#333333] leading-[26px] ">
                  {" "}
                  Ink specially formulated for immersion or dipping processes,
                  providing durable and flexible coatings on various substrates.
                </p>
              </div>
              <Link onClick={handleClick} to={`/detail/product?title=Silicone flocking effect`}>
                <div
                  className="min-h-[220px] w-full  border border-[#bec4c7]  py-[15px] px-[15px]"
                  style={{ borderColor: borderColors[12] }}
                  onClick={() => handleClick("Silicone flocking effect", 12)}
                >
                  <p className="text-[18px]  font-700 text-[black] font-700">
                    Silicone flocking effect
                  </p>
                  <p className="text-[14px] pt-2  text-[#333333] leading-[26px] ">
                    {" "}
                    A surface treatment technique utilizing silicone particles
                    to create a soft and textured finish with a velvety
                    appearance.
                  </p>
                </div>
              </Link>
              <Link onClick={handleClick} to={`/detail/product?title=high`}>
                <div
                  className="min-h-[220px] w-full  border border-[#bec4c7]  py-[15px] px-[15px]"
                  style={{ borderColor: borderColors[13] }}
                  onClick={() => handleClick("High density silicone", 13)}
                >
                  <p className="text-[18px]  font-700 text-[black] font-700">
                    High density silicone
                  </p>
                  <p className="text-[14px] pt-2  text-[#333333] leading-[26px] ">
                    {" "}
                    It is a long established hen looking at its layout. The
                    point of using Lorem Ipsum is that
                  </p>
                </div>
              </Link>
              <Link onClick={handleClick} to={`/detail/product?title=high`}>
                <div
                  className="min-h-[220px] w-full  border border-[#bec4c7]  py-[15px] px-[15px]"
                  style={{ borderColor: borderColors[14] }}
                  onClick={() => handleClick("High density silicone 3D", 14)}
                >
                  <p className="text-[18px]  font-700 text-[black] font-700">
                    High density silicone 3D
                  </p>
                  <p className="text-[14px] pt-2  text-[#333333] leading-[26px] ">
                    {" "}
                    Silicone material with a higher concentration, often used to
                    create thicker and more substantial coatings in various
                    applications.
                  </p>
                </div>
              </Link>
              <Link onClick={handleClick} to={`/detail/product?title=high`}>
                <div
                  className="min-h-[220px] w-full  border border-[#bec4c7]  py-[15px] px-[15px]"
                  style={{ borderColor: borderColors[15] }}
                  onClick={() => handleClick("High density silsdcone 3D", 15)}
                >
                  <p className="text-[18px]  font-700 text-[black] font-700">
                    Soft high density
                  </p>
                  <p className="text-[14px] pt-2  text-[#333333] leading-[26px] ">
                    {" "}
                    Silicone-based ink designed to create raised effects with a
                    plush and Soft flexible texture on printed surfaces.
                  </p>
                </div>
              </Link>
            </div>
          </div>
        ) : title === "Auxiliaries" ? (
          <div className="bg-white my-16 md:my-32 py-16 px-4 md:px-12 mx-4 md:mx-20">
            <p className="text-[20px] md:text-[24px] pb-8">
              {" "}
              5 Products Recommendations
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8  ">
              <Link onClick={handleClick} to={`/detail/product?title=binders`}>
                <div
                  className="h-[250px] w-full  border border-[#bec4c7] py-[15px] px-[15px]"
                  style={{ borderColor: borderColors[16] }}
                  onClick={() => handleClick("binders", 16)}
                >
                  <p className="text-[18px]  font-700 text-[black] font-700 ">
                    Binders
                  </p>
                  <p className="text-[14px] pt-2  text-[#333333] leading-[26px] ">
                    {" "}
                    Binders are substances used to hold together ingredients or
                    particles in a cohesive manner, often applied in various
                    industries such as pharmaceuticals, food, and manufacturing.
                  </p>
                </div>
              </Link>
              <Link onClick={handleClick} to={`/detail/product?title=Thikners`}>
                <div
                  className="h-[250px] w-full  border border-[#bec4c7]  py-[15px] px-[15px]"
                  style={{ borderColor: borderColors[17] }}
                  onClick={() => handleClick("Thickner", 17)}
                >
                  <p className="text-[18px]  font-700 text-[black] font-700">
                    Thickner
                  </p>
                  <p className="text-[14px] pt-2  text-[#333333] leading-[26px] ">
                    {" "}
                    Thickener is a substance added to a solution or mixture to
                    increase its viscosity or thickness, commonly used in food,
                    cosmetics, and industrial processes.
                  </p>
                </div>{" "}
              </Link>
              <Link onClick={handleClick} to={`/detail/product?title=reducer`}>
                <div
                  className="h-[250px] w-full  border border-[#bec4c7]  py-[15px] px-[15px]"
                  style={{ borderColor: borderColors[18] }}
                  onClick={() => handleClick("Reducer", 18)}
                >
                  <p className="text-[18px]  font-700 text-[black] font-700 ">
                    Reducer
                  </p>
                  <p className="text-[14px] pt-2  text-[#333333] leading-[26px] ">
                    {" "}
                    A reducer is a solvent or additive used to dilute the
                    concentration or viscosity of a substance, often employed in
                    paints, coatings, and chemical processes.
                  </p>
                </div>{" "}
              </Link>
              <Link onClick={handleClick} to={`/detail/product?title=choking`}>
                <div
                  className="h-[250px] w-full  border border-[#bec4c7]  py-[15px] px-[15px]"
                  style={{ borderColor: borderColors[19] }}
                  onClick={() => handleClick("Anti Choking", 19)}
                >
                  <p className="text-[18px]  font-700 text-[black] font-700 ">
                    Anti Choking
                  </p>
                  <p className="text-[14px] pt-2  text-[#333333] leading-[26px] ">
                    {" "}
                    "Anti-choking" refers to measures or techniques aimed at
                    preventing or alleviating choking incidents, typically
                    related to food or foreign objects obstructing the airway.
                  </p>
                </div>{" "}
              </Link>
              <Link onClick={handleClick} to={`/detail/product?title=cleaner`}>
                <div
                  className="h-[250px] w-full  border border-[#bec4c7] py-[15px] px-[15px]"
                  style={{ borderColor: borderColors[20] }}
                  onClick={() => handleClick("Screen cleaner", 20)}
                >
                  <p className="text-[18px]  font-700 text-[black] font-700 ">
                    Screen cleaner
                  </p>
                  <p className="text-[14px] pt-2  text-[#333333] leading-[26px] ">
                    {" "}
                    A screen cleaner is a solution or device used to remove
                    dust, fingerprints, and other debris from electronic
                    screens, such as those on smartphones, tablets, and computer
                    monitors.
                  </p>
                </div>
              </Link>
            </div>
          </div>
        ) : title === "Eco Series" ? (
          <div className="bg-white my-16 md:my-32 py-16 px-4 md:px-12 mx-4 md:mx-20">
            <p className="text-[20px] md:text-[24px] pb-8">
              {" "}
              4 Products Recommendations
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8  ">
              <Link onClick={handleClick} to={`/detail/product?title=eco`}>
                <div
                  className="h-[300px] w-full  border border-[#bec4c7] py-[15px] px-[15px]"
                  style={{ borderColor: borderColors[21] }}
                  onClick={() => handleClick("PVC & Phthalate free white", 21)}
                >
                  <p className="text-[18px]  font-700 text-[black] font-700 ">
                    PVC & Phthalate free white
                  </p>
                  <p className="text-[14px] pt-2  text-[#333333] leading-[26px] ">
                    {" "}
                    "PVC free white" refers to white-colored materials or
                    products that are manufactured without the use of polyvinyl
                    chloride (PVC), often sought for their environmental
                    friendliness and reduced toxicity.
                  </p>
                </div>
              </Link>
              <Link onClick={handleClick} to={`/detail/product?title=ecoblack`}>
                <div
                  className="h-[300px]  w-full  border border-[#bec4c7]  py-[15px] px-[15px]"
                  style={{ borderColor: borderColors[22] }}
                  onClick={() => handleClick("ecoblack", 22)}
                >
                  <p className="text-[18px]  font-700 text-[black] font-700">
                    PVC & Phthalate free black
                  </p>
                  <p className="text-[14px] pt-2  text-[#333333] leading-[26px] ">
                    {" "}
                    "PVC free Black" refers to white-colored materials or
                    products that are manufactured without the use of polyvinyl
                    chloride (PVC), often sought for their environmental
                    friendliness and reduced toxicity.
                  </p>
                </div>{" "}
              </Link>
              <div
                className="h-[300px]  w-full  border border-[#bec4c7]  py-[15px] px-[15px]"
                style={{ borderColor: borderColors[23] }}
                onClick={() => handleClick("ecoblacksds", 23)}
              >
                <p className="text-[18px]  font-700 text-[black] font-700 ">
                  PVC & Phthalate free clears
                </p>
                <p className="text-[14px] pt-2  text-[#333333] leading-[26px] ">
                  {" "}
                  "PVC-free clear ink" refers to transparent ink formulations
                  that do not contain polyvinyl chloride (PVC), commonly used in
                  printing processes for labels, and other applications where
                  clarity and eco-friendliness are priorities.
                </p>
              </div>{" "}
              <Link onClick={handleClick} to={`/detail/product?title=shades`}>
                <div
                  className="h-[300px]  w-full  border border-[#bec4c7]  py-[15px] px-[15px]"
                  style={{ borderColor: borderColors[24] }}
                  onClick={() => handleClick("shades", 24)}
                >
                  <p className="text-[18px]  font-700 text-[black] font-700 ">
                    PVC & Phthalate free shades
                  </p>
                  <p className="text-[14px] pt-2  text-[#333333] leading-[26px] ">
                    {" "}
                    "PVC-free Color ink" refers to transparent ink formulations
                    that do not contain polyvinyl chloride (PVC), commonly used
                    in printing processes for labels, and other applications
                    where clarity and eco-friendliness are priorities.
                  </p>
                </div>
              </Link>
            </div>
          </div>
        ) : title === "Fluorescent Inks" ? (
          <div className="bg-white my-16 md:my-32 py-16 px-4 md:px-12 mx-4 md:mx-20">
            <p className="text-[20px] md:text-[24px] pb-8">
              {" "}
              2 Products Recommendations
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8  ">
              <Link onClick={handleClick} to={`/detail/product?title=Water base`}>
                <div
                  className="h-[250px] w-full  border border-[#bec4c7] py-[15px] px-[15px]"
                  style={{ borderColor: borderColors[25] }}
                  onClick={() => handleClick("Water base", 25)}
                >
                  <p className="text-[18px]  font-700 text-[black] font-700 ">
                    Water base
                  </p>
                  <p className="text-[14px] pt-2  text-[#333333] leading-[26px] ">
                    {" "}
                    Water-based ink is a type of ink formulation where water
                    serves as the primary solvent, commonly used in various
                    printing processes for its eco-friendliness and low
                    toxicity.
                  </p>
                </div>
              </Link>
              <Link onClick={handleClick} to={`/detail/product?title=Plastisol`}>
                <div
                  className="h-[250px] w-full  border border-[#bec4c7] py-[15px] px-[15px]"
                  style={{ borderColor: borderColors[26] }}
                  onClick={() => handleClick("Plastisol", 26)}
                >
                  <p className="text-[18px]  font-700 text-[black] font-700 ">
                    Plastisol
                  </p>
                  <p className="text-[14px] pt-2  text-[#333333] leading-[26px] ">
                    {" "}
                    Plastisol oil-based ink is a type of ink composed of PVC
                    particles suspended in a liquid plasticizer, cured by heat
                    to form a solid, commonly used in textile screen printing
                    for its durability and vibrant colors.
                  </p>
                </div>
              </Link>
            </div>
          </div>
        ) : title === "Process CMYK Inks" ? (
          <div className="bg-white my-16 md:my-32 py-16 px-4 md:px-12 mx-4 md:mx-20">
            <p className="text-[20px] md:text-[24px] pb-8">
              {" "}
              4 Products Recommendations
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8  ">
              <Link onClick={handleClick} to={`/detail/product`}>
                <div
                  className="min-h-[220px] w-full  border border-[#bec4c7] py-[15px] px-[15px]"
                  style={{ borderColor: borderColors[27] }}
                  onClick={() => handleClick("product", 27)}
                >
                  <p className="text-[18px]  font-700 text-[black] font-700 ">
                    Process CMYK Blue
                  </p>
                  <p className="text-[14px] pt-2  text-[#333333] leading-[26px] ">
                    {" "}
                    It is a long established hen looking at its layout. The
                    point of using Lorem Ipsum is that
                  </p>
                </div>
              </Link>
              <div
                className="min-h-[220px] w-full  border border-[#bec4c7] py-[15px] px-[15px]"
                style={{ borderColor: borderColors[28] }}
                onClick={() => handleClick("Process CMYK Black", 28)}
              >
                <p className="text-[18px]  font-700 text-[black] font-700 ">
                  Process CMYK Black
                </p>
                <p className="text-[14px] pt-2  text-[#333333] leading-[26px] ">
                  {" "}
                  It is a long established hen looking at its layout. The point
                  of using Lorem Ipsum is that
                </p>
              </div>
              <div
                className="min-h-[220px] w-full  border border-[#bec4c7] py-[15px] px-[15px]"
                style={{ borderColor: borderColors[29] }}
                onClick={() => handleClick("Process CMYK Yellow", 29)}
              >
                <p className="text-[18px]  font-700 text-[black] font-700 ">
                  Process CMYK Yellow
                </p>
                <p className="text-[14px] pt-2  text-[#333333] leading-[26px] ">
                  {" "}
                  It is a long established hen looking at its layout. The point
                  of using Lorem Ipsum is that
                </p>
              </div>
              <div
                className="min-h-[220px] w-full  border border-[#bec4c7] py-[15px] px-[15px]"
                style={{ borderColor: borderColors[30] }}
                onClick={() => handleClick("Process CMYK Magenta", 30)}
              >
                <p className="text-[18px]  font-700 text-[black] font-700 ">
                  Process CMYK Magenta
                </p>
                <p className="text-[14px] pt-2  text-[#333333] leading-[26px] ">
                  {" "}
                  It is a long established hen looking at its layout. The point
                  of using Lorem Ipsum is that
                </p>
              </div>
            </div>
          </div>
        ) : title === "Discharge Inks" ? (
          <div className="bg-white my-16 md:my-32 py-16 px-4 md:px-12 mx-4 md:mx-20">
            <p className="text-[20px] md:text-[24px] pb-8">
              {" "}
              3 Products Recommendations
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8  ">
              <Link onClick={handleClick} to={`/detail/product`}>
                <div
                  className="min-h-[220px] w-full  border border-[#bec4c7] py-[15px] px-[15px]"
                  style={{ borderColor: borderColors[31] }}
                  onClick={() => handleClick("product", 31)}
                >
                  <p className="text-[18px]  font-700 text-[black] font-700 ">
                    Discharge opaque white
                  </p>
                  <p className="text-[14px] pt-2  text-[#333333] leading-[26px] ">
                    {" "}
                    It is a long established hen looking at its layout. The
                    point of using Lorem Ipsum is that
                  </p>
                </div>
              </Link>
              <div
                className="min-h-[220px] w-full  border border-[#bec4c7] py-[15px] px-[15px]"
                style={{ borderColor: borderColors[32] }}
                onClick={() => handleClick("produwct", 32)}
              >
                <p className="text-[18px]  font-700 text-[black] font-700 ">
                  Discharge opaque clearing
                </p>
                <p className="text-[14px] pt-2  text-[#333333] leading-[26px] ">
                  {" "}
                  It is a long established hen looking at its layout. The point
                  of using Lorem Ipsum is that
                </p>
              </div>
              <div
                className="min-h-[220px] w-full  border border-[#bec4c7] py-[15px] px-[15px]"
                style={{ borderColor: borderColors[32] }}
                onClick={() => handleClick("productsdssd", 32)}
              >
                <p className="text-[18px]  font-700 text-[black] font-700 ">
                  Discharge powder
                </p>
                <p className="text-[14px] pt-2  text-[#333333] leading-[26px] ">
                  {" "}
                  It is a long established hen looking at its layout. The point
                  of using Lorem Ipsum is that
                </p>
              </div>
            </div>
          </div>
        ) : title === "Ink additives" ? (
          <div className="bg-white my-16 md:my-32 py-16 px-4 md:px-12 mx-4 md:mx-20">
            <p className="text-[20px] md:text-[24px] pb-8">
              {" "}
              3 Products Recommendations
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8  ">
              <Link onClick={handleClick} to={`/detail/product`}>
                <div
                  className="min-h-[220px] w-full  border border-[#bec4c7] py-[15px] px-[15px]"
                  style={{ borderColor: borderColors[33] }}
                  onClick={() => handleClick("productsdsd", 33)}
                >
                  <p className="text-[18px]  font-700 text-[black] font-700 ">
                    Water base ink additives
                  </p>
                  <p className="text-[14px] pt-2  text-[#333333] leading-[26px] ">
                    {" "}
                    It is a long established hen looking at its layout. The
                    point of using Lorem Ipsum is that
                  </p>
                </div>
              </Link>
              <div
                className="min-h-[220px] w-full  border border-[#bec4c7] py-[15px] px-[15px]"
                style={{ borderColor: borderColors[34] }}
                onClick={() => handleClick("pro", 34)}
              >
                <p className="text-[18px]  font-700 text-[black] font-700 ">
                  Plastisol ink additives
                </p>
                <p className="text-[14px] pt-2  text-[#333333] leading-[26px] ">
                  {" "}
                  It is a long established hen looking at its layout. The point
                  of using Lorem Ipsum is that
                </p>
              </div>
              <div
                className="min-h-[220px] w-full  border border-[#bec4c7] py-[15px] px-[15px]"
                style={{ borderColor: borderColors[35] }}
                onClick={() => handleClick("prodfdfduct", 35)}
              >
                <p className="text-[18px]  font-700 text-[black] font-700 ">
                  Solvent based ink additives
                </p>
                <p className="text-[14px] pt-2  text-[#333333] leading-[26px] ">
                  {" "}
                  It is a long established hen looking at its layout. The point
                  of using Lorem Ipsum is that
                </p>
              </div>
            </div>
          </div>
        ) : title === "Solvent based ink" ? (
          <div className="bg-white my-16 md:my-32 py-16 px-4 md:px-12 mx-4 md:mx-20">
            <p className="text-[20px] md:text-[24px] pb-8">
              {" "}
              2 Products Recommendations
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8  ">
              <Link onClick={handleClick} to={`/detail/product`}>
                <div
                  className="min-h-[220px] w-full  border border-[#bec4c7] py-[15px] px-[15px]"
                  style={{ borderColor: borderColors[36] }}
                  onClick={() => handleClick("productA", 36)}
                >
                  <p className="text-[18px]  font-700 text-[black] font-700 ">
                    Parachute White
                  </p>
                  <p className="text-[14px] pt-2  text-[#333333] leading-[26px] ">
                    {" "}
                    Solvent white ink is an ink formulation containing solvents
                    as a carrier, used in printing to apply opaque white layers
                    on various substrates, typically for signage and graphics.
                  </p>
                </div>
              </Link>
              <div
                className="min-h-[220px] w-full  border border-[#bec4c7] py-[15px] px-[15px]"
                style={{ borderColor: borderColors[37] }}
                onClick={() => handleClick("productB", 37)}
              >
                <p className="text-[18px]  font-700 text-[black] font-700 ">
                  Parachute clear
                </p>
                <p className="text-[14px] pt-2  text-[#333333] leading-[26px] ">
                  {" "}
                  Solvent Transparent ink is an ink formulation containing
                  solvents as a carrier, used in printing to apply opaque Color
                  Shades layers on various substrates, typically for
                  signage and graphics.
                </p>
              </div>
            </div>
          </div>
        ) : title === "" ? (
          <div className="bg-white my-16 md:my-32 py-16 px-4 md:px-12 mx-4 md:mx-20">
            <p className="text-[20px] md:text-[24px] pb-8">
              {" "}
              3 Products Recommendations
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8  ">
              <Link onClick={handleClick} to={`/detail/product`}>
                <div
                  className="min-h-[220px] w-full  border border-[#bec4c7] py-[15px] px-[15px]"
                  style={{ borderColor: borderColors[38] }}
                  onClick={() => handleClick("productC", 38)}
                >
                  <p className="text-[18px]  font-700 text-[black] font-700 ">
                    Water base ink additives
                  </p>
                  <p className="text-[14px] pt-2  text-[#333333] leading-[26px] ">
                    {" "}
                    It is a long established hen looking at its layout. The
                    point of using Lorem Ipsum is that
                  </p>
                </div>
              </Link>
              <div
                className="min-h-[220px] w-full  border border-[#bec4c7] py-[15px] px-[15px]"
                style={{ borderColor: borderColors[39] }}
                onClick={() => handleClick("productD", 39)}
              >
                <p className="text-[18px]  font-700 text-[black] font-700 ">
                  Plastisol ink additives
                </p>
                <p className="text-[14px] pt-2  text-[#333333] leading-[26px] ">
                  {" "}
                  It is a long established hen looking at its layout. The point
                  of using Lorem Ipsum is that
                </p>
              </div>
              <div className="min-h-[220px] w-full  border border-[#bec4c7] py-[15px] px-[15px]">
                <p className="text-[18px]  font-700 text-[black] font-700 ">
                  Solvent based ink additives
                </p>
                <p className="text-[14px] pt-2  text-[#333333] leading-[26px] ">
                  {" "}
                  It is a long established hen looking at its layout. The point
                  of using Lorem Ipsum is that
                </p>
              </div>
            </div>
          </div>
        ) : title === "Plastisol Inks" ? (
          <div className="bg-white my-16 md:my-32 py-16 px-4 md:px-12 mx-4 md:mx-20">
            <p className="text-[20px] md:text-[24px] pb-8">
              {" "}
              7 Products Recommendations
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8  ">
              <Link onClick={handleClick} to={`/detail/product?title=freewhite`}>
                <div
                  className="min-h-[220px] w-full  border border-[#bec4c7] py-[15px] px-[15px]"
                  style={{ borderColor: borderColors[40] }}
                  onClick={() => handleClick("freewhite", 40)}
                >
                  <p className="text-[18px]  font-700 text-[black] font-700 ">
                    Phthalate free whites{" "}
                  </p>
                  <p className="text-[14px] pt-2  text-[#333333] leading-[26px] ">
                    {" "}
                    Ink formulation devoid of phthalates, commonly used in
                    screen printing for textiles, ensuring environmental and
                    health safety.
                  </p>
                </div>
              </Link>
              <Link onClick={handleClick} to={`/detail/product?title=freeblack`}>
                <div
                  className="min-h-[220px] w-full  border border-[#bec4c7] py-[15px] px-[15px]"
                  style={{ borderColor: borderColors[41] }}
                  onClick={() => handleClick("freeblack", 41)}
                >
                  <p className="text-[18px]  font-700 text-[black] font-700 ">
                    Phthalate free black
                  </p>
                  <p className="text-[14px] pt-2  text-[#333333] leading-[26px] ">
                    {" "}
                    Ink formulation devoid of phthalates, commonly used in
                    screen printing for textiles, ensuring environmental
                    and health safety.
                  </p>
                </div>
              </Link>
              <Link onClick={handleClick} to={`/detail/product?title=freeshades`}>
                <div
                  className="min-h-[220px] w-full  border border-[#bec4c7] py-[15px] px-[15px]"
                  style={{ borderColor: borderColors[42] }}
                  onClick={() => handleClick("freeblack", 42)}
                >
                  <p className="text-[18px]  font-700 text-[black] font-700 ">
                    Phthalate free shades
                  </p>
                  <p className="text-[14px] pt-2  text-[#333333] leading-[26px] ">
                    {" "}
                    Ink formulas void of phthalates, offering a range of color
                    options while ensuring environmental and health safety.
                  </p>
                </div>
              </Link>
              <Link onClick={handleClick} to={`/detail/product?title=metalics`}>
                <div
                  className="min-h-[220px] w-full  border border-[#bec4c7] py-[15px] px-[15px]"
                  style={{ borderColor: borderColors[43] }}
                  onClick={() => handleClick("metalics", 43)}
                >
                  <p className="text-[18px]  font-700 text-[black] font-700 ">
                    Phthalate free metallic
                  </p>
                  <p className="text-[14px] pt-2  text-[#333333] leading-[26px] ">
                    {" "}
                    Ink formulas void of phthalates, offering a range of color
                    options while ensuring environmental and health safety.
                  </p>
                </div>
              </Link>
              <Link onClick={handleClick} to={`/detail/product?title=additives`}>
                <div
                  className="min-h-[220px] w-full  border border-[#bec4c7] py-[15px] px-[15px]"
                  style={{ borderColor: borderColors[44] }}
                  onClick={() => handleClick("additives", 44)}
                >
                  <p className="text-[18px]  font-700 text-[black] font-700 ">
                    Plastisol Additives
                  </p>
                  <p className="text-[14px] pt-2  text-[#333333] leading-[26px] ">
                    {" "}
                    Additional substances mixed with plastisol inks to modify
                    viscosity, improve adhesion, or alter other printing
                    properties.
                  </p>
                </div>
              </Link>
              <div className="min-h-[220px] w-full  border border-[#bec4c7] py-[15px] px-[15px]">
                <p
                  className="text-[18px]  font-700 text-[black] font-700 "
                  style={{ borderColor: borderColors[45] }}
                  onClick={() => handleClick("additdddfives", 45)}
                >
                  Phthalate free special effects
                </p>
                <p className="text-[14px] pt-2  text-[#333333] leading-[26px] ">
                  {" "}
                  Specialized printing effects achieved without the use of
                  phthalates, ensuring environmental and health safety
                  standards.
                </p>
              </div>
              <Link onClick={handleClick} to={`/detail/product?title=Anti`}>
                <div
                  className="min-h-[220px] w-full  border border-[#bec4c7] py-[15px] px-[15px]"
                  style={{ borderColor: borderColors[46] }}
                  onClick={() => handleClick("Anti", 46)}
                >
                  <p className="text-[18px]  font-700 text-[black] font-700 ">
                    Anti-Migration
                  </p>
                  <p className="text-[14px] pt-2  text-[#333333] leading-[26px] ">
                    {" "}
                    Ink designed to prevent the unwanted spreading or bleeding
                    of colors, ensuring precise and high-quality printing
                    results.
                  </p>
                </div>
              </Link>
            </div>
          </div>
        ) : title === "Heat Transfer Powders" ? (
          <div className="bg-white my-16 md:my-32 py-16 px-4 md:px-12 mx-4 md:mx-20">
            <p className="text-[20px] md:text-[24px] pb-8">
              {" "}
              2 Products Recommendations
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8  ">
              <Link onClick={handleClick} to={`/detail/product?title=Powder`}>
                <div
                  className="h-[250px] w-full  border border-[#bec4c7] py-[15px] px-[15px]"
                  style={{ borderColor: borderColors[47] }}
                  onClick={() => handleClick("Powder", 47)}
                >
                  <p className="text-[18px]  font-700 text-[black] font-700 ">
                    Transfer Melting Powder
                  </p>
                  <p className="text-[14px] pt-2  text-[#333333] leading-[26px] ">
                    {" "}
                    Heat transfer powders are fine particles used in transfer
                    printing processes, activated by heat to adhere designs onto
                    substrates such as textiles or ceramics.
                  </p>
                </div>
              </Link>

              <div
                className="h-[250px] w-full  border border-[#bec4c7] py-[15px] px-[15px]"
                style={{ borderColor: borderColors[48] }}
                onClick={() => handleClick("Powderdfdf", 48)}
              >
                <p className="text-[18px]  font-700 text-[black] font-700 ">
                  DTF Transfer Powder
                </p>
                <p className="text-[14px] pt-2  text-[#333333] leading-[26px] ">
                  {" "}
                  DTF transfer powder refers to a fine powder used in Direct to
                  Film (DTF) printing technology, facilitating the transfer of
                  designs onto substrates such as textiles through heat and
                  pressure.
                </p>
              </div>
            </div>
          </div>
        ) : null}
        <Footer />
      </div>
    </div>
  );
}
