import Footer from "../components/Footer";
import logo from "../logo.svg";
import Header from "../components/Header";
import SwiperComponents from "../components/SwiperComponents";
import LogoCarouel from "../components/LogoCarousel";
import CommonCard from "../components/CommonCard";
import ChooseUsCard from "../components/ChooseUsCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { motion } from "framer-motion";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import review1 from "../../src/assets/review1.png";
import "../App.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import CategoryCard from "../components/CategoryCard";
import Marquee from "react-fast-marquee";
import Coating from "../../src/assets/Coating.jpg";
import Dyering from "../../src/assets/Dyeing.png";
import Printing from "../../src/assets/Printing.jpg";
import Washing from "../../src/assets/Washing.jpg";
import Pretreatment from "../../src/assets/Pretreatment.jpg";
import Finishing from "../../src/assets/Finishing.jpg";
import CallButton from "../components/CallButton";
import MainContent from "../components/MainContent";
import ProductCard from "../components/ProductCard";
import Quality from "../../src/assets/Quality.png";
import Innovation from "../../src/assets/Innovation.png";
import Reliability from "../../src/assets/Reliability.png";
import Trust from "../../src/assets/Trust.png";
import TechnicalSupport from "../../src/assets/TechnicalSupport.png";
import Ecological from "../../src/assets/Ecological.png";
import Excellence from "../../src/assets/Excellence.png";
import Commonitment from "../../src/assets/Commitment.png";
import { useState } from "react";
import FaqItem from "../components/FaqItem";
import { faqs } from "../staticData.jsx/Faq";
import FaqImage from "../../src/assets/FAQImage.jpg";
import ReviewSection from "../components/ReviewSection";
import AdditivesCard from "../components/AdditivesCard";
import Eventcard from "../components/Eventcard";
import BccWater from "../assets/Bcc_Water based.png";
import Plastisol from "../assets/Bcc_Plastisol Inks.png";
import Silicone from "../assets/Bcc_Silicon Inks.png";

import Auxilures from "../assets/Bcc_Auxiliaries.png";
import EcoSeries from "../assets/Bcc_Eco.png";
import Special from "../assets/Bcc_Special Effets.png";
import Lurist from "../assets/Bcc_Fluorescent Inks.png";
import slovent from "../assets/Bcc_Solvent.png";
import HeatPowder from "../assets/Bcc_Transfer Powder.png";
import CMYK from "../assets/Bcc_CMYK Inks.png"

export default function Home() {
  useEffect(() => {
    AOS.init();
  }, []);
  const [isOpen, setIsOpen] = useState(false);

  const toggleFaq = () => {
    setIsOpen(!isOpen);
  };
  const PRODUCTLIST = [
    {
      image: BccWater,
      text: "Water Based Inks",
      color: "#f6b02e",
      description:
        "Water based inks are defined as inks that use water as the main solvent for carrying pigment. They are made up mostly of acrylic resins or hybrid blends of acrylic resin and polyurethanes.          ",
    },
    {
      image: Plastisol,
      text: "Plastisol Inks",
      color: "#2f97ab",
      description:
        "A plastisol is a colloidal dispension of small polymer particles, usually polyvinyl chloride, in a liquid plasticizer. When heated to around 180 °C, the plastic particles absorb the plasticizer, causing them to swell and fuse together forming a viscous gel.",
    },
    {
      image: Silicone,
      text: "Silicone Inks",
      color: "#7fb641",
      description:
        "Silicone ink, also called screen printing silicone or silicone-based ink is a kind of environmentally protective screen printing ink that is used for printing on fabric and leather. for example, on T-shirts or Shoes. It is mainly used in the textile printing industry.",
    },
    {
      image: Auxilures,
      text: "Auxiliaries",
      color: "#894a97",
      description:
        " Auxiliaries are additives that enhance ink properties, such as viscosity, adhesion, or drying time, optimizing printing processes.",
    },
    {
      image: EcoSeries,
      text: "Eco Series",
      color: "#f6b02e",
      description:
        "A PVC free screen printing ink that works like plastisol, thereby offering the ease of use and many other advantages that come from plastisol, without any PVC, phthalates, or any other harmful substances.",
    },
    {
      image: Special,
      text: "Special Effects",
      color: "#2f97ab",
      description:
        "Special Effects refer to unique visual enhancements like metallic finishes, holography, or fluorescence, elevating print aesthetics.",
    },
    {
      image: Lurist,
      text: "Fluorescent Inks",
      color: "#7fb641",
      description:
        " Fluorescent inks are pigmented printing inks that emit vibrant, highly visible colors under ultraviolet (UV) light, commonly used for attention-grabbing signage and promotional materials.",
    },
    {
      image: CMYK,
      text: "Process CMYK Inks",
      color: "#894a97",
      customLink: `/detail/product?title=processcmk&image=${CMYK}`,
      description:
        "Process CMYK inks are the standard four-color printing inks (cyan, magenta, yellow, and black) used in color printing to reproduce a wide range of colors by layering dots of these primary colors.",
    },
    {
      image:
        "http://dtpainting.wpenginepowered.com/wp-content/uploads/2016/03/restoration.jpg",
      text: "Discharge Inks",
      color: "#f6b02e",
      customLink: "/detail/product?title=discharge",
      description:
        "Discharge inks are specialized printing inks that remove or discharge the dye from dyed fabrics, creating a bleached or lightened effect while depositing pigment to create new color tones.",
    },
    {
      image:
        "http://dtpainting.wpenginepowered.com/wp-content/uploads/2016/03/restoration.jpg",
      text: "Ink additives",
      color: "#2f97ab",
      customLink: "/detail/product?title=inks",
      description:
        "Ink additives are supplementary substances incorporated into ink formulations to modify or enhance their properties, such as drying time, viscosity, adhesion, or color intensity.",
    },
    {
      image: slovent,
      text: "Solvent based ink",
      color: "#7fb641",
      description:
        "Solvent-based ink is a type of ink formulation where solvents serve as the primary carrier, evaporating upon drying, commonly used in printing for its fast-drying properties and versatility on various substrates.",
    },
    {
      image: HeatPowder,
      text: "Heat Transfer Powders",
      color: "#894a97",
      description:
        "Heat transfer powders are fine particles used in transfer printing processes, activated by heat to adhere designs onto substrates such as textiles or ceramics.            ",
    },
    {
      image:
        "http://dtpainting.wpenginepowered.com/wp-content/uploads/2016/03/restoration.jpg",
      text: "Heat Transfer Sheets",
      color: "#f6b02e",
      customLink: "/detail/product?title=transfersheet",
      description:
        "Heat transfer sheets are flexible substrates used in heat transfer printing to carry printed designs from a carrier to a substrate through the application of heat and pressure.",
    },
  ];
  const [expanded, setExpanded] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  // const [filteredProducts, setFilteredProducts] = useState(PRODUCTLIST);

  const openSideBar = () => {
    setExpanded(!expanded);
  };

  //  productlist

  // const handleSearch = (query) => {
  //   setSearchQuery(query);
  //   const filtered = PRODUCTLIST?.filter(product =>
  //     product.text && product.text.toLowerCase().includes(query.toLowerCase())
  //   );
  //   setFilteredProducts(filtered);
  // };
  const filteredProducts =
    searchQuery === ""
      ? PRODUCTLIST
      : PRODUCTLIST.filter((product) =>
        product.text.toLowerCase().includes(searchQuery)
      );

  return (
    <div className=" font-poppins">
      <Header searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
      <SwiperComponents />
      <div className=" block md:hidden">
        <div className="h-[174px] bg-[#f0f1f1] font-calibri ">
          <h2 className="text-center text-[32px] pt-5 text-[#79ba44] leading-[27px]">
            Sustainability
          </h2>
          <p className="text-center text-[18px] pt-2 text-[#282a2a] font-[300]">
            We are part of Ecological Coating Ink Solutions
          </p>
          <div className="h-[50px] bg-[#f0f1f1] font-calibri text-center flex justify-center pt-3">
            <button className="w-32 bg-transparent border border-[#79ba44] text-[#79ba44] font-calibri font-semibold py-2 hover:bg-[#f0f1f1] hover:border-[#79ba44]">
              Find Out More
            </button>
          </div>
        </div>
      </div>
      <CallButton />
      <div className="text-center font-poppins  mx-5  ">
        <p className="text-[25px] lg:text-[30px] md:text-4xl  font-calibri mb-5  w-full mt-5 text-[#79ba44]  leading-9">
          Explore BCC
        </p>
      </div>

      <div
        className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 mx-5 md:mx-10  gap-4 md:gap-4 "
        data-aos="fade-up"
      >
        {filteredProducts.map((item, index) => {
          return (
            <ProductCard
              image={item.image}
              text={item.text}
              color={item.color}
              customLink={item?.customLink}
              description={item.description}
            />
          );
        })}
      </div>
      <div className="bg-[#7fb641] font-calibri mt-10">
        <h2 className="text-center text-[32px] pt-5 text-[white] py-4">
          Upcoming Products
        </h2>

        <div
          className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 mx-5 md:mx-10  gap-4 md:gap-4  py-4 "
          data-aos="fade-up"
        >
          <AdditivesCard
            title={"Dye Auxiliary Chemicals"}
            text={
              "Chemicals used to aid dyeing processes, enhancing color, consistency, and durability of dyed materials."
            }
          />
          <AdditivesCard
            title={"Reflective Ink"}
            text={
              "Ink that reflects light, often used for safety or aesthetic purposes in various applications."
            }
          />
          <AdditivesCard
            title={"Biodegrade Ink"}
            text={
              "Ink that naturally breaks down over time, reducing environmental impact without lasting pollution or harm."
            }
          />
          {/* <AdditivesCard />
          <AdditivesCard />
          <AdditivesCard />
          <AdditivesCard />
          <AdditivesCard />
          <AdditivesCard />
          <AdditivesCard /> */}
        </div>
      </div>

      {/* <h1 className="text-[24px] lg:text-[28px] md:text-4xl font-bold text-center w-full mt-10">
          Why Choose <span style={{ color: "#fab900" }}> Us</span>
        </h1> */}
      {/* <div className="text-center font-poppins  mx-5 ">
          <p className="text-[28px] lg:text-[34px] md:text-3xl  font-extrabold  space-y-3 w-full mt-10 text-[#232122] leading-9">
            Why Choose <span style={{ color: "#fab900" }}> Us</span>
          </p>
        </div> */}
      {/* <div className="mx-auto max-w-screen-md mt-4 text-center font-poppins  text-[#232122] text-justify">
          <p className="text-[14px] lg:text-[15px]  md:text-xl mx-5 md:mx-0 mb-5 ">
            BBC has established its name by its core values that leads the pathway of the company. We continue to provide our value added services that has been well appreciated and valued in the industry.
          </p>
        </div> */}
      {/* <div className=" mt-4 mx-[1%]  md:mx-[8%]  text-center font-poppins  text-[#232122] ">
          <p className="text-[14px] lg:text-[15px]  md:text-xl mx-10 md:mx-0 mb-5 text-center ">
            BBC has established its name by its core values that leads the pathway of the company. We continue to provide our value added services that has been well appreciated and valued in the industry.

          </p>
        </div> */}

      {/* 
        <div data-aos="fade-up" class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 mx-0  md:mx-3 gap-4"  >
          <ChooseUsCard
            imageChooseUs={Quality}
            head='Quality'
            detail='Quality comes from departmental efforts, planned actions, and conformity to market requirements .'
          />
          <ChooseUsCard
            imageChooseUs={Reliability}
            head='Reliability'
            detail='Quality comes from departmental efforts, planned actions, and conformity to market requirements .'
          />
          <ChooseUsCard
            imageChooseUs={Innovation}
            head='Innovation'
            detail='Quality comes from departmental efforts, planned actions, and conformity to market requirements .'
          />
          <ChooseUsCard
            imageChooseUs={Trust}
            head='Trust'
            detail='Quality comes from departmental efforts, planned actions, and conformity to market requirements .'
          />  <ChooseUsCard
            imageChooseUs={Ecological}
            head='Ecological'
            detail='Quality comes from departmental efforts, planned actions, and conformity to market requirements .'
          />  <ChooseUsCard
            imageChooseUs={Excellence}
            head='Excellence'
            detail='Quality comes from departmental efforts, planned actions, and conformity to market requirements .'
          />  <ChooseUsCard
            imageChooseUs={TechnicalSupport}
            head='TechnicalSupport'
            detail='Quality comes from departmental efforts, planned actions, and conformity to market requirements .'
          />  <ChooseUsCard
            imageChooseUs={Commonitment}
            head='Commonitment'
            detail='Quality comes from departmental efforts, planned actions, and conformity to market requirements .'
          />
        </div> */}

      {/* <div className='hidden md:flex'>
        <div className="relative mt-10 flex flex-col items-center ">
          <img
            src={FaqImage}
            alt="Image Alt Text"
            className='h-[1500px] w-full max-w-full '
          />

          <div className="absolute inset-0 flex items-center justify-center">
            <div className="w-full max-w-screen-lg p-4 text-red  bg-opacity-75">
              <section className="bg-[black] bg-opacity-25 dark:bg-gray-900 w-full">
                <div className="py-10">
                  <h1 className="text-2xl font-bold text-center lg:text-[28px] text-white" >Our Business Lines</h1>
                  <div className="mt-4 font-poppins text-white">
                    <p className="text-sm lg:text-lg md:text-[14px] mx-4 md:mx-0 mb-5 px-5 text-center">
                      When you have Berger you can find the right product for any of your Paint need. We are offering variety of products that caters to your all paint related issues.
                    </p>
                  </div>
                  {faqs.map((faq, index) => (
                    <FaqItem key={index} question={faq.question} answer={faq.answer} />
                  ))}
                </div>
              </section>
            </div>
          </div>
        </div>

      </div> */}
      {/* <div className="block md:hidden">
        <div className="relative bg-cover bg-center h-[1500px]" style={{ backgroundImage: `url(${FaqImage})` }}>
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="w-full max-w-screen-lg p-4 text-red  bg-opacity-75">
              <section className="bg-[black] bg-opacity-25 dark:bg-gray-900 w-full">
                <div className="py-10">
                  <h1 className="text-2xl font-bold text-center lg:text-[28px] text-white" >Our Business Lines</h1>
                  <div className="mt-4 font-poppins text-white">
                    <p className="text-sm lg:text-lg md:text-[14px] mx-4 md:mx-0 mb-5 px-5 text-center">
                      When you have Berger you can find the right product for any of your Paint need. We are offering variety of products that caters to your all paint related issues.
                    </p>
                  </div>
                  {faqs.map((faq, index) => (
                    <FaqItem key={index} question={faq.question} answer={faq.answer} />
                  ))}
                </div>
              </section>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className=" sm:px-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 mt-20 font-poppins">
        <div>
          <h1 className="font-poppins text-[32px] text-center leading-9 md:text-start text-[#232122] md:text-5xl lg:text-5xl font-bold mt-5 sm:mt-0">
            CLIENT TESTIMONIAL
          </h1>
          <p className=" mt-2 md:mt-2 text-center md:text-start text-[12px] md:text-[16px]">
            GET TO KNOW ALL ABOUT OUR SERVICE THROUGH VARIOUS BUSINESS

            CLIENTS.
          </p>

          <div className="mt-3 ">
            <div className="flex justify-center md:justify-start items-center">
              <img
                src="https://oxopackaging.com/assets/images/commas.webp"
                alt="rating"
              />
            </div>

            <Swiper className="w-full   mt-3 mb-10" data-aos="fade-right">
              <SwiperSlide>
                <ReviewSection
                  head="A S"
                  subHeading="Awais Star"
                  parag="BCC Pakistan is fast and reliable! I worked with Zack Mackenzie for my packaging needs for my business and I have a strong feeling we will be doing a..."
                />
              </SwiperSlide>
              <SwiperSlide>
                <ReviewSection
                  head="M U"
                  subHeading="Muhammad Umair"
                  parag="BCC Pakistan is fast and reliable! I worked with Zack Mackenzie for my packaging needs for my business and I have a strong feeling we will be doing a..."

                />
              </SwiperSlide>
              <SwiperSlide>
                <ReviewSection
                  head="K A"
                  subHeading="Kina Alia"
                  parag="BCC Pakistan is fast and reliable! I worked with Zack Mackenzie for my packaging needs for my business and I have a strong feeling we will be doing a..."
                />
              </SwiperSlide>
            </Swiper>


          </div>

        </div>

        <div className="hidden md:flex mt-10 mb-20" data-aos="fade-right">
          <img
            src="https://oxopackaging.com/assets/images/layer-139.webp"
            alt="rating"
          />
        </div>
      </div> */}

      {/* <MainContent /> */}
      {/* <div className='mx-0 md:mx-7'>
        <Marquee pauseOnHover={true} className='my-10  w-full'>
          <div
            style={{

              width: 320
            }}
          >
            <CategoryCard text={'Coating'} image={Coating} />
          </div>
          <div
            style={{
              marginLeft: 20,
              marginRight: 20, // Add a transition effect for opacity

              width: 320
            }}
          >
            <CategoryCard
              text={'Dyering'}
              image={Dyering}
            />
          </div>
          <div
            style={{
              marginLeft: 20,
              marginRight: 20, // Add a transition effect for opacity

              width: 320
            }}
          >
            <CategoryCard
              text={'Finishing'}
              image={Finishing}
            />
          </div>
          <div
            style={{
              marginLeft: 20,
              marginRight: 20, // Add a transition effect for opacity

              width: 320
            }}
          >
            <CategoryCard text={'Pretreatment'} image={Pretreatment} />
          </div>{' '}
          <div
            style={{
              marginLeft: 20,
              marginRight: 20, // Add a transition effect for opacity

              width: 320
            }}
          >
            <CategoryCard
              text={'Printing'}
              image={Printing}
            />{' '}
          </div>{' '}
          <div
            style={{
              marginLeft: 20,
              marginRight: 20, // Add a transition effect for opacity

              width: 320
            }}
          >
            <CategoryCard
              text={'Washing'}
              image={Washing}
            />
          </div>{' '}
        </Marquee>
      </div> */}
      {/* <div className='grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 mx-5 md:mx-10  gap-4 md:gap-4 ' data-aos="fade-up" >

        <AdditivesCard />
      </div> */}

      <div
        onClick={openSideBar}
        className={`flying-cta top right ${expanded ? "active" : ""}`}
        style={{ borderTopLeftRadius: "30px", borderBottomLeftRadius: "30px" }}
        data-event-content="pill button top right active"
      >
        <div class="flying-cta__inner">
          <div class="flying-cta__text">
            <a> Since 2014</a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
