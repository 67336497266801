import React from "react";
import Header from "../components/Header";
import DetailImage from "../assets/detail-image.jpg";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import CMYK from "../assets/Bcc_CMYK Inks.png";
import Discharge from "../assets/Bcc_Discharge Ink.png"
import Ink from "../assets/Bcc_Ink Aditives.png"
import heat from  "../assets/Heat-Transfer (1).jpg"
export default function NestedProduct() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // Retrieve individual parameters
  const title = searchParams.get("title");

  return (
    <div className="font-poppins ">
      <Header />
      {title == "Table printing series" ? (
        <div className="bg-[#F5F5F5] min-h-screen pt-4 md:pt-20 ">
          <div className="bg-[#79ba44] mx-4 mt-6 md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              WB-071 Ecosole Base White
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Environmentally friendly white inks formulated with
                  eco-conscious materials for sustainable printing applications.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              WB-003 Ecosole Smart White 100
            </p>

            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Environmentally friendly white inks formulated with
                  eco-conscious materials for sustainable printing applications.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-4xl lg:text-4xl  mb-0 text-[white]">
              WB-004 Ecosole Smart White 100 Clear
            </p>

            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  A transparent environmentally friendly ink designed for
                  sustainable and smart printing applications
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-4xl lg:text-4xl  mb-0 text-[white]">
              WB-002 Ecosole Soft Clear
            </p>

            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  A transparent environmentally friendly ink designed for
                  sustainable and smart printing applications
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-4xl lg:text-4xl  mb-0 text-[white]">
              WB-001 Ecosole Soft White
            </p>

            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Environmentally friendly white inks formulated with
                  eco-conscious materials for sustainable printing applications.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12 mb-16">
            <div className="flex justify-center items-center mt-10">
              <Link to="mailto:info@bccpakistan.com">
                <button className="bg-[#F5F5F5] text-[#79ba44] font-bold py-4 px-8 rounded">
                  Contact Us
                </button>
              </Link>
            </div>
          </div>

          <Footer />
        </div>
      ) : title == "Machine grade series" ? (
        <div className="bg-[#F5F5F5] min-h-screen pt-4 md:pt-20 ">
          <div className="bg-[#79ba44] mx-4 mt-6 md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              WB-0706 Ecosole Familiar Clear
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  A transparent environmentally friendly ink designed for
                  sustainable and smart printing applications
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              WB-042 Ecosole Familiar White
            </p>

            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Environmentally friendly white inks formulated with
                  eco-conscious materials for sustainable printing applications.
                </p>
              </div>
            </div>
          </div>

          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12 mb-16">
            <div className="flex justify-center items-center mt-10">
              <Link to="mailto:info@bccpakistan.com">
                <button className="bg-[#F5F5F5] text-[#79ba44] font-bold py-4 px-8 rounded">
                  Contact Us
                </button>
              </Link>
            </div>
          </div>

          <Footer />
        </div>
      ) : title == "Pu" ? (
        <div className="bg-[#F5F5F5] min-h-screen pt-4 md:pt-20 ">
          <div className="bg-[#79ba44] mx-4 mt-6 md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              WB-041 Ecosole Premium Storm Clear
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  A transparent environmentally friendly ink designed for
                  sustainable and smart printing applications{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              WB-040 Ecosole Premium Storm White
            </p>

            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Environmentally friendly white inks formulated with
                  eco-conscious materials for sustainable printing applications.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              B-009 Ecosole Table Gum
            </p>

            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Silicone surface additive for solvent-borne coating systems
                  with a medium reduction of surface tension and a medium
                  increase of surface slip.
                </p>
              </div>
            </div>
          </div>

          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12 mb-16">
            <div className="flex justify-center items-center mt-10">
              <Link to="mailto:info@bccpakistan.com">
                <button className="bg-[#F5F5F5] text-[#79ba44] font-bold py-4 px-8 rounded">
                  Contact Us
                </button>
              </Link>
            </div>
          </div>

          <Footer />
        </div>
      ) : title == "Non Blocking Series" ? (
        <div className="bg-[#F5F5F5] min-h-screen pt-4 md:pt-20 ">
          <div className="bg-[#79ba44] mx-4 mt-6 md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              WBATMG Ecosole WB-Anti-Migration Grey
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  A grey ink formulated to prevent migration, designed for
                  environmentally friendly printing applications.
                </p>
              </div>
            </div>
          </div>

          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12 mb-16">
            <div className="flex justify-center items-center mt-10">
              <Link to="mailto:info@bccpakistan.com">
                <button className="bg-[#F5F5F5] text-[#79ba44] font-bold py-4 px-8 rounded">
                  Contact Us
                </button>
              </Link>
            </div>
          </div>

          <Footer />
        </div>
      ) : title == "Water based special effects" ? (
        <div className="bg-[#F5F5F5] min-h-screen pt-4 md:pt-20 ">
          <div className="bg-[#79ba44] mx-4 mt-6 md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              WB-059 Ecosole WB Premium Metallic Gold
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Environmentally friendly water-based metallic gold ink of
                  premium quality, suitable for various printing applications.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              WB-060 Ecosole WB Premium Metallic Silver
            </p>

            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Environmentally friendly water-based metallic SILVER ink of
                  premium quality, suitable for various printing applications.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-4xl lg:text-4xl  mb-0 text-[white]">
              WB-061 Ecosole Spectra Clear
            </p>

            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  A transparent environmentally friendly ink designed for
                  sustainable and smart printing applications
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-4xl lg:text-4xl  mb-0 text-[white]">
              WB-062 Ecosole Spectra White
            </p>

            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Environmentally friendly white inks formulated with
                  eco-conscious materials for sustainable printing applications.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-4xl lg:text-4xl  mb-0 text-[white]">
              WB-063 Ecosole Water Based High Bold White
            </p>

            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Environmentally friendly white ink with high opacity and
                  density, suitable for water-based printing applications.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-4xl lg:text-4xl  mb-0 text-[white]">
              WB-064 Ecosole Water Puff Binder
            </p>

            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Environmentally friendly ink designed to create raised or
                  puffed effects when printed and dried using water-based
                  methods.
                </p>
              </div>
            </div>
          </div>{" "}
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-4xl lg:text-4xl  mb-0 text-[white]">
              WB-065 Ecosole Clear GS Extra
            </p>

            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Environmentally friendly ink that produces glossy finishes,
                  enhancing visual appeal in printed materials.
                </p>
              </div>
            </div>
          </div>{" "}
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12 mb-16">
            <div className="flex justify-center items-center mt-10">
              <Link to="mailto:info@bccpakistan.com">
                <button className="bg-[#F5F5F5] text-[#79ba44] font-bold py-4 px-8 rounded">
                  Contact Us
                </button>
              </Link>
            </div>
          </div>
          <Footer />
        </div>
      ) : title == "Water based pigments" ? (
        <div className="bg-[#F5F5F5] min-h-screen pt-4 md:pt-20 ">
          <div className="bg-[#79ba44] mx-4 mt-6 md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              FLCP-060 Ecosole Collapse Florosent Pigment Orange
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Environmentally friendly orange pigment with fluorescent
                  properties, suitable for eco-conscious printing applications.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              FLCP-061 Ecosole Collapse Florosent Pigment Pink
            </p>

            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Environmentally friendly Pink pigment with fluorescent
                  properties, suitable for eco-conscious printing applications.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-4xl lg:text-4xl  mb-0 text-[white]">
              CP-0055 Ecosole Collapse Pigment Black
            </p>

            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Environmentally sustainable black pigment ink designed for
                  eco-friendly printing applications.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-4xl lg:text-4xl  mb-0 text-[white]">
              CP-0051 Ecosole Collapse Pigment Blue
            </p>

            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Environmentally sustainable Blue pigment ink designed for
                  eco-friendly printing applications.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-4xl lg:text-4xl  mb-0 text-[white]">
              CP-0054 Ecosole Collapse Pigment Green
            </p>

            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Environmentally sustainable Green pigment ink designed for
                  eco-friendly printing applications.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-4xl lg:text-4xl  mb-0 text-[white]">
              CP-0053 Ecosole Collapse Pigment lemon Yellow
            </p>

            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Environmentally sustainable Lemon Yellow pigment ink designed
                  for eco-friendly printing applications.
                </p>
              </div>
            </div>
          </div>{" "}
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-4xl lg:text-4xl  mb-0 text-[white]">
              CP-0052 Ecosole Collapse Pigment Orange
            </p>

            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Environmentally sustainable Orange pigment ink designed for
                  eco-friendly printing applications.
                </p>
              </div>
            </div>
          </div>{" "}
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-4xl lg:text-4xl  mb-0 text-[white]">
              CP-0059 Ecosole Collapse Pigment Pink
            </p>

            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Environmentally sustainable Pink pigment ink designed for
                  eco-friendly printing applications.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-4xl lg:text-4xl  mb-0 text-[white]">
              CP-0050 Ecosole Collapse Pigment Red
            </p>

            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Environmentally sustainable Red pigment ink designed for
                  eco-friendly printing applications.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-4xl lg:text-4xl  mb-0 text-[white]">
              CP-0057 Ecosole Collapse Pigment Turquoise blue
            </p>

            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Environmentally sustainable Turquoise Blue pigment ink
                  designed for eco-friendly printing applications.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-4xl lg:text-4xl  mb-0 text-[white]">
              CP-0056 Ecosole Collapse Pigment Violet
            </p>

            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Environmentally sustainable Violet pigment ink designed for
                  eco-friendly printing applications.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12 mb-16">
            <div className="flex justify-center items-center mt-10">
              <Link to="mailto:info@bccpakistan.com">
                <button className="bg-[#F5F5F5] text-[#79ba44] font-bold py-4 px-8 rounded">
                  Contact Us
                </button>
              </Link>
            </div>
          </div>
          <Footer />
        </div>
      ) : title == "Transfer silicone" ? (
        <div className="bg-[#F5F5F5] min-h-screen pt-4 md:pt-20 ">
          <div className="bg-[#79ba44] mx-4 mt-6 md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              SL-025 Ecosole Premium Silicone White
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Silicone White heat transfer is a method of transferring heat
                  using silicone-based materials, typically in the form of pads
                  or compounds, to improve thermal conductivity and heat
                  dissipation.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              SL-001 Ecosole Premium Silicone Black
            </p>

            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Silicone Black heat transfer is a method of transferring heat
                  using silicone-based materials, typically in the form of pads
                  or compounds, to improve thermal conductivity and
                  heat dissipation.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12 mb-16">
            <div className="flex justify-center items-center mt-10">
              <Link to="mailto:info@bccpakistan.com">
                <button className="bg-[#F5F5F5] text-[#79ba44] font-bold py-4 px-8 rounded">
                  Contact Us
                </button>
              </Link>
            </div>
          </div>
          <Footer />
        </div>
      ) : title == "Anti slip silicone" ? (
        <div className="bg-[#F5F5F5] min-h-screen pt-4 md:pt-20 ">
          <div className="bg-[#79ba44] mx-4 mt-6 md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              SL-383 Ecosole Premium Silicone Jell
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Embossing silicone white involves creating raised patterns or
                  textures on a silicone surface using a white-colored material,
                  typically for decorative or branding purposes.
                </p>
              </div>
            </div>
          </div>

          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12 mb-16">
            <div className="flex justify-center items-center mt-10">
              <Link to="mailto:info@bccpakistan.com">
                <button className="bg-[#F5F5F5] text-[#79ba44] font-bold py-4 px-8 rounded">
                  Contact Us
                </button>
              </Link>
            </div>
          </div>
          <Footer />
        </div>
      ) : title == "Silicone flocking effect" ? (
        <div className="bg-[#F5F5F5] min-h-screen pt-4 md:pt-20 ">
          <div className="bg-[#79ba44] mx-4 mt-6 md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              SL-3833 Ecosole Flocking Silicon Jell
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Embossing silicone jelly involves creating raised patterns or
                  textures on a silicone gel surface, often for decorative or
                  taxtile purposes.
                </p>
              </div>
            </div>
          </div>

          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12 mb-16">
            <div className="flex justify-center items-center mt-10">
              <Link to="mailto:info@bccpakistan.com">
                <button className="bg-[#F5F5F5] text-[#79ba44] font-bold py-4 px-8 rounded">
                  Contact Us
                </button>
              </Link>
            </div>
          </div>
          <Footer />
        </div>
      ) : title == "high" ? (
        <div className="bg-[#F5F5F5] min-h-screen pt-4 md:pt-20 ">
          <div className="bg-[#79ba44] mx-4 mt-6 md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              SL-3833 Ecosole Flocking Silicon Jell
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Silicone surface additive for solvent-borne coating systems
                  with a medium reduction of surface tension and a medium
                  increase of surface slip.
                </p>
              </div>
            </div>
          </div>

          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12 mb-16">
            <div className="flex justify-center items-center mt-10">
              <Link to="mailto:info@bccpakistan.com">
                <button className="bg-[#F5F5F5] text-[#79ba44] font-bold py-4 px-8 rounded">
                  Contact Us
                </button>
              </Link>
            </div>
          </div>
          <Footer />
        </div>
      ) : title == "binders" ? (
        <div className="bg-[#F5F5F5] min-h-screen pt-4 md:pt-20 ">
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              B-005 Ecological Binder UT
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Ecological Binder UT typically stands for "Binder Ultra Thin,"
                  referring to a type of adhesive or bonding agent that offers
                  exceptionally thin application or coating properties
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              B-001 Ecological CBR Binder
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Ecological Binder CBR typically stands for "Binder Ultra
                  Thin," referring to a type of adhesive or bonding agent.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              B-002 Ecological Foil Binder
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  A foil binder is an adhesive or coating used to affix metallic
                  foil onto surfaces, commonly employed in printing, packaging,
                  and decorative applications.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4 md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              WB-008 Ecological Glitter Base
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Silicone surface additive for solvent-borne coating systems
                  with a medium reduction of surface tension and a medium
                  increase of surface slip.
                </p>
              </div>
            </div>
          </div>

          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12 mb-16">
            <div className="flex justify-center items-center mt-10">
              <Link to="mailto:info@bccpakistan.com">
                <button className="bg-[#F5F5F5] text-[#79ba44] font-bold py-4 px-8 rounded">
                  Contact Us
                </button>
              </Link>
            </div>
          </div>
          <Footer />
        </div>
      ) : title == "Thikners" ? (
        <div className="bg-[#F5F5F5] min-h-screen pt-4 md:pt-20 ">
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              A-010 Ecological Alka PTF
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  A thickener is a substance added to a solution or mixture to
                  increase its viscosity or thickness
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              B-001 Ecological CBR Binder
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Silicone surface additive for solvent-borne coating systems
                  with a medium reduction of surface tension and a medium
                  increase of surface slip.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              B-002 Ecological Foil Binder
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Silicone surface additive for solvent-borne coating systems
                  with a medium reduction of surface tension and a medium
                  increase of surface slip.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4 md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              WB-008 Ecological Glitter Base
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Silicone surface additive for solvent-borne coating systems
                  with a medium reduction of surface tension and a medium
                  increase of surface slip.
                </p>
              </div>
            </div>
          </div>

          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12 mb-16">
            <div className="flex justify-center items-center mt-10">
              <Link to="mailto:info@bccpakistan.com">
                <button className="bg-[#F5F5F5] text-[#79ba44] font-bold py-4 px-8 rounded">
                  Contact Us
                </button>
              </Link>
            </div>
          </div>
          <Footer />
        </div>
      ) : title == "Reducer" ? (
        <div className="bg-[#F5F5F5] min-h-screen pt-4 md:pt-20 ">
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              PFA-004 Ecosole Reducer Oil
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Silicone surface additive for solvent-borne coating systems
                  with a medium reduction of surface tension and a medium
                  increase of surface slip.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              B-001 Ecological CBR Binder
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Silicone surface additive for solvent-borne coating systems
                  with a medium reduction of surface tension and a medium
                  increase of surface slip.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              B-002 Ecological Foil Binder
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Silicone surface additive for solvent-borne coating systems
                  with a medium reduction of surface tension and a medium
                  increase of surface slip.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4 md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              WB-008 Ecological Glitter Base
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Silicone surface additive for solvent-borne coating systems
                  with a medium reduction of surface tension and a medium
                  increase of surface slip.
                </p>
              </div>
            </div>
          </div>

          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12 mb-16">
            <div className="flex justify-center items-center mt-10">
              <Link to="mailto:info@bccpakistan.com">
                <button className="bg-[#F5F5F5] text-[#79ba44] font-bold py-4 px-8 rounded">
                  Contact Us
                </button>
              </Link>
            </div>
          </div>
          <Footer />
        </div>
      ) : title == "cleaner" ? (
        <div className="bg-[#F5F5F5] min-h-screen pt-4 md:pt-20 ">
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              A-005 Ecosole Screen Cleaner
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Silicone surface additive for solvent-borne coating systems
                  with a medium reduction of surface tension and a medium
                  increase of surface slip.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12 mb-16">
            <div className="flex justify-center items-center mt-10">
              <Link to="mailto:info@bccpakistan.com">
                <button className="bg-[#F5F5F5] text-[#79ba44] font-bold py-4 px-8 rounded">
                  Contact Us
                </button>
              </Link>
            </div>
          </div>
          <Footer />
        </div>
      ) : title == "eco" ? (
        <div className="bg-[#F5F5F5] min-h-screen pt-4 md:pt-20 ">
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              ECO 025 Ecosole ECO Rabbit White
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  "PVC-free Rabbit White" likely refers to a white-colored
                  material or product that is manufactured without the use of
                  polyvinyl chloride (PVC), potentially chosen for its
                  environmental considerations and absence of PVC-related
                  concerns.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              ECO 383 Ecosole ECO Clear
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  "PVC-free ECO Clear" likely refers to a Transparent -colored
                  material or product that is manufactured without the use of
                  polyvinyl chloride (PVC), potentially chosen for its
                  environmental considerations and absence of
                  PVC-related concerns.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12 mb-16">
            <div className="flex justify-center items-center mt-10">
              <Link to="mailto:info@bccpakistan.com">
                <button className="bg-[#F5F5F5] text-[#79ba44] font-bold py-4 px-8 rounded">
                  Contact Us
                </button>
              </Link>
            </div>
          </div>
          <Footer />
        </div>
      ) : title == "ecoblack" ? (
        <div className="bg-[#F5F5F5] min-h-screen pt-4 md:pt-20 ">
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              ECO 001 Ecosole ECO Jet Black
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  "PVC-free Jet Black" likely refers to a Black-colored material
                  or product that is manufactured without the use of polyvinyl
                  chloride (PVC), potentially chosen for its environmental
                  considerations and absence of PVC-related concerns.
                </p>
              </div>
            </div>
          </div>

          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12 mb-16">
            <div className="flex justify-center items-center mt-10">
              <Link to="mailto:info@bccpakistan.com">
                <button className="bg-[#F5F5F5] text-[#79ba44] font-bold py-4 px-8 rounded">
                  Contact Us
                </button>
              </Link>
            </div>
          </div>
          <Footer />
        </div>
      ) : title == "shades" ? (
        <div className="bg-[#F5F5F5] min-h-screen pt-4 md:pt-20 ">
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              ECO 134 Ecosole ECO Blood Red
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Silicone surface additive for solvent-borne coating systems
                  with a medium reduction of surface tension and a medium
                  increase of surface slip.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              ECO-016 Ecosole ECO Violet
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Silicone surface additive for solvent-borne coating systems
                  with a medium reduction of surface tension and a medium
                  increase of surface slip.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              ECO 134A Ecosole Eco Dark Red
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  "PVC-free Dark Red" indicates a dark red-colored material or
                  product that is manufactured without the use of polyvinyl
                  chloride (PVC), often chosen for environmental reasons and to
                  avoid PVC-related concerns or This product is color shade in
                  PVC-free metrical.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              ECO 043 Ecosole ECO Golden Yellow
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  "PVC-free Golden Yellow" indicates a dark red-colored material
                  or product that is manufactured without the use of polyvinyl
                  chloride (PVC), often chosen for environmental reasons and to
                  avoid PVC-related concerns or This product is color shade in
                  PVC-free metrical.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              ECO-006 Ecosole Eco Green
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  "PVC-free Green" indicates a dark red-colored material or
                  product that is manufactured without the use of polyvinyl
                  chloride (PVC), often chosen for environmental reasons and to
                  avoid PVC-related concerns or This product is color shade in
                  PVC-free metrical.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              ECO 145 Ecosole ECO Lemon Yellow
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  "PVC-free Lemon Yellow" indicates a dark red-colored material
                  or product that is manufactured without the use of polyvinyl
                  chloride (PVC), often chosen for environmental reasons and to
                  avoid PVC-related concerns or This product is color shade in
                  PVC-free metrical.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              ECO 166 Ecosole ECO Magenta
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  "PVC-free Magenta" indicates a dark red-colored material or
                  product that is manufactured without the use of polyvinyl
                  chloride (PVC), often chosen for environmental reasons and to
                  avoid PVC-related concerns or This product is color shade in
                  PVC-free metrical.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              ECO 207 Ecosole ECO Navy Blue
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  "PVC-free Navy Blue" indicates a dark red-colored material or
                  product that is manufactured without the use of polyvinyl
                  chloride (PVC), often chosen for environmental reasons and to
                  avoid PVC-related concerns or This product is color shade in
                  PVC-free metrical.
                </p>
              </div>
            </div>
          </div>

          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12 mb-16">
            <div className="flex justify-center items-center mt-10">
              <Link to="mailto:info@bccpakistan.com">
                <button className="bg-[#F5F5F5] text-[#79ba44] font-bold py-4 px-8 rounded">
                  Contact Us
                </button>
              </Link>
            </div>
          </div>
          <Footer />
        </div>
      ) : title == "Water base" ? (
        <div className="bg-[#F5F5F5] min-h-screen pt-4 md:pt-20 ">
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              FLCP-159 Ecosole Fluorescent Pigment Green
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Fluorescent pigment green is a type of green-colored pigment
                  that exhibits fluorescence, emitting visible light when
                  exposed to ultraviolet (UV) light sources.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              FLCP-160 Ecosole Fluorescent Pigment Turquoise Blue
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Fluorescent pigment Turquoise Blue is a type of green-colored
                  pigment that exhibits fluorescence, emitting visible light
                  when exposed to ultraviolet (UV) light sources.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              FLCP-158 Ecosole Fluorescent Pigment Yellow
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Fluorescent pigment yellow is a type of green-colored pigment
                  that exhibits fluorescence, emitting visible light when
                  exposed to ultraviolet (UV) light sources.
                </p>
              </div>
            </div>
          </div>

          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12 mb-16">
            <div className="flex justify-center items-center mt-10">
              <Link to="mailto:info@bccpakistan.com">
                <button className="bg-[#F5F5F5] text-[#79ba44] font-bold py-4 px-8 rounded">
                  Contact Us
                </button>
              </Link>
            </div>
          </div>
          <Footer />
        </div>
      ) : title == "Plastisol" ? (
        <div className="bg-[#F5F5F5] min-h-screen pt-4 md:pt-20 ">
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              FL-051 Ecosole Fluorescent Green
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Fluorescent Green oil-based ink is a type of ink formulation
                  containing oil-based pigments that fluoresce under ultraviolet
                  (UV) light, often used in printing for its vivid color and
                  special visual effects.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              FL-050 Ecosole Fluorescent Lemon Yellow
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Fluorescent Lemon Yellow oil-based ink is a type of ink
                  formulation containing oil-based pigments that fluoresce under
                  ultraviolet (UV) light, often used in printing for its vivid
                  color and special visual effects.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              FL-055 Ecosole Fluorescent Magenta
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Fluorescent Magenta oil-based ink is a type of ink formulation
                  containing oil-based pigments that fluoresce under ultraviolet
                  (UV) light, often used in printing for its vivid color and
                  special visual effects.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              FL-053 Ecosole Fluorescent Orange
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Fluorescent Orange oil-based ink is a type of ink formulation
                  containing oil-based pigments that fluoresce under ultraviolet
                  (UV) light, often used in printing for its vivid color and
                  special visual effects.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              Fl-052 Ecosole Fluorescent Pink
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Fluorescent Pink oil-based ink is a type of ink formulation
                  containing oil-based pigments that fluoresce under ultraviolet
                  (UV) light, often used in printing for its vivid color and
                  special visual effects.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              FL-054 Ecosole Fluorescent Red
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Fluorescent Red oil-based ink is a type of ink formulation
                  containing oil-based pigments that fluoresce under ultraviolet
                  (UV) light, often used in printing for its vivid color and
                  special visual effects.
                </p>
              </div>
            </div>
          </div>

          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12 mb-16">
            <div className="flex justify-center items-center mt-10">
              <Link to="mailto:info@bccpakistan.com">
                <button className="bg-[#F5F5F5] text-[#79ba44] font-bold py-4 px-8 rounded">
                  Contact Us
                </button>
              </Link>
            </div>
          </div>
          <Footer />
        </div>
      ) : title == "processcmk" ? (
        <div className=" min-h-screen pt-4 md:pt-4  md:mx-20 my-20">

        <div className="bg-[#79ba44]     p-4 md:p-12">
          <p className="text-2xl md:text-4xl lg:text-5xl  mb-4 text-white">
           Process CMYK
          </p>

          <div className="flex flex-col md:flex-row pt-8">
            <div className="md:w-1/3 pr-0 md:pr-8">
              <img
                src={CMYK}
                className="w-full h-[400]"
                alt="Water base ink 
                            "
              />
            </div>
            <div className="md:w-1/2 md:ml-4 mt-4 md:mt-0">
              <p className="text-white md:text-lg lg:text-xl">Process CMYK inks are the standard four-color printing inks (cyan, magenta, yellow, and black) used in color printing to reproduce a wide range of colors by layering dots of these primary colors.</p>
            </div>
          </div>
          <div className="bg-[#79ba44]   pt-4 md:pt-12">
          <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
            PR-207 Ecosole Process Blue
          </p>
          <div className="flex flex-col md:flex-row pt-2">
            <div className="md:w-full mt-1 md:mt-1">
              <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                Process Black ink is a standard black ink used in four-color
                printing processes, composed of a mix of cyan Blue, magenta,
                yellow, and black inks to achieve a neutral black tone.
              </p>
            </div>
          </div>
        </div>
        <div className="bg-[#79ba44]   pt-4 md:pt-12">
        <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
          PR-167 Ecosole Process Magenta
        </p>
        <div className="flex flex-col md:flex-row pt-2">
          <div className="md:w-full mt-1 md:mt-1">
            <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
              Process Black ink is a standard black ink used in four-color
              printing processes, composed of a mix of cyan Blue, magenta,
              yellow, and black inks to achieve a neutral black tone.
            </p>
          </div>
        </div>
      </div>
      <div className="bg-[#79ba44]    pt-4 md:pt-12">
        <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
          PR-043 Ecosole Process Yellow
        </p>
        <div className="flex flex-col md:flex-row pt-2">
          <div className="md:w-full mt-1 md:mt-1">
            <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
              Process Black ink is a standard black ink used in four-color
              printing processes, composed of a mix of cyan Blue, magenta,
              yellow, and black inks to achieve a neutral black tone.
            </p>
          </div>
        </div>
      </div>
      <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12 mb-16">
        <div className="flex justify-center items-center mt-10">
          <Link to="mailto:info@bccpakistan.com">
            <button className="bg-[#F5F5F5] text-[#79ba44] font-bold py-4 px-8 rounded">
              Contact Us
            </button>
          </Link>
        </div>
      </div>
          </div>
        
         
          <Footer />
        </div>
      ) : title == "discharge" ? (

        <div className=" min-h-screen pt-4 md:pt-4  md:mx-20 my-20">

        <div className="bg-[#79ba44]     p-4 md:p-12">
          <p className="text-2xl md:text-4xl lg:text-5xl  mb-4 text-white">
          Discharge Inks
          </p>

          <div className="flex flex-col md:flex-row pt-8">
            <div className="md:w-1/3 pr-0 md:pr-8">
              <img
                src={Discharge}
                className="w-full h-[400]"
                alt="Water base ink 
                            "
              />
            </div>
            <div className="md:w-1/2 md:ml-4 mt-4 md:mt-0">
              <p className="text-white md:text-lg lg:text-xl">Discharge inks are specialized printing inks that remove or discharge the dye from dyed fabrics, creating a bleached or lightened effect while depositing pigment to create new color tones.</p>
            </div>
          </div>
          <div className="bg-[#79ba44]   pt-4 md:pt-12">
          <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
            PH-383 Ecological Discharge opaque Clear
          </p>
          <div className="flex flex-col md:flex-row pt-2">
            <div className="md:w-full mt-1 md:mt-1">
              <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                Discharge clear ink is a transparent ink used in printing
                processes to remove or discharge the dye from dyed fabrics,
                leaving a clear or white pattern behind.
              </p>
            </div>
          </div>
        </div>
        <div className="bg-[#79ba44]   pt-4 md:pt-12">
          <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
            T-006 Ecological Discharge Powder
          </p>
          <div className="flex flex-col md:flex-row pt-2">
            <div className="md:w-full mt-1 md:mt-1">
              <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                Discharge powder is a chemical substance applied in printing
                processes to remove or discharge the color from dyed fabrics,
                leaving behind a clear or white pattern.
              </p>
            </div>
          </div>
        </div>
        <div className="bg-[#79ba44]  pt-4 md:pt-12">
          <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
            PH-025 Ecological Discharge opaque White
          </p>
          <div className="flex flex-col md:flex-row pt-2">
            <div className="md:w-full mt-1 md:mt-1">
              <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                Discharge white ink is a type of ink used in printing
                processes to remove or discharge the color from dyed fabrics
                while simultaneously depositing a white pigment, creating a
                white pattern on the fabric.
              </p>
            </div>
          </div>
        </div>
        <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12 mb-16">
          <div className="flex justify-center items-center mt-10">
            <Link to="mailto:info@bccpakistan.com">
              <button className="bg-[#F5F5F5] text-[#79ba44] font-bold py-4 px-8 rounded">
                Contact Us
              </button>
            </Link>
          </div>
        </div>
          </div>
        
         
          <Footer />
        </div>
      ) : title == "inks" ? (
    
        
        <div className=" min-h-screen pt-4 md:pt-4  md:mx-20 my-20">

        <div className="bg-[#79ba44]     p-4 md:p-12">
          <p className="text-2xl md:text-4xl lg:text-5xl  mb-4 text-white">
          Ink additives
                    </p>

          <div className="flex flex-col md:flex-row pt-8">
            <div className="md:w-1/3 pr-0 md:pr-8">
              <img
                src={Ink}
                className="w-full h-[400]"
                alt="Water base ink 
                            "
              />
            </div>
            <div className="md:w-1/2 md:ml-4 mt-4 md:mt-0">
              <p className="text-white md:text-lg lg:text-xl">Ink additives are supplementary substances incorporated into ink formulations to modify or enhance their properties, such as drying time, viscosity, adhesion, or color intensity.</p>
            </div>
            
          </div>
          <div className="bg-[#79ba44]  pt-4 md:pt-12">
          <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
            PF-0162 Oil Base Fixer
          </p>
          <div className="flex flex-col md:flex-row pt-2">
            <div className="md:w-full mt-1 md:mt-1">
              <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                Oil-based fixer is a substance used in printing processes to
                set or stabilize oil-based inks on various surfaces.
              </p>
            </div>
          </div>
        </div>
        <div className="bg-[#79ba44]   pt-4 md:pt-12">
          <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
            WB-A021 Water Base Fixer
          </p>
          <div className="flex flex-col md:flex-row pt-2">
            <div className="md:w-full mt-1 md:mt-1">
              <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                Water-based fixer is a substance used in printing processes to
                set or stabilize Water-based inks on various surfaces.
              </p>
            </div>
          </div>
        </div>
        <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12 mb-16">
          <div className="flex justify-center items-center mt-10">
            <Link to="mailto:info@bccpakistan.com">
              <button className="bg-[#F5F5F5] text-[#79ba44] font-bold py-4 px-8 rounded">
                Contact Us
              </button>
            </Link>
          </div>
        </div>
          </div>
        
         
          <Footer />
        </div>
      ) : title == "freewhite" ? (
        <div className="bg-[#F5F5F5] min-h-screen pt-4 md:pt-20 ">
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              BP-025A Ecosole Bright White
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  A highly opaque and vibrant white ink commonly used in screen
                  printing applications for textiles and other materials.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              UP-025A Ecosole Flash White
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  A highly opaque and vibrant white ink commonly used in screen
                  printing applications for textiles and other materials.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              PF-025A Ecosole Gorgeous White
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  A white ink with exceptional coverage and aesthetic appeal,
                  designed for eco-friendly printing applications.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              PF-025A Ecosole High Stretch White
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  White ink formulated with high elasticity for printing on
                  stretchable materials, ideal for versatile and durable
                  applications.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              PF-026 Ecosole LB Poli White
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  A white ink with low bleed characteristics, suitable for
                  printing on various materials while minimizing color bleeding.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12 mb-16">
            <div className="flex justify-center items-center mt-10">
              <Link to="mailto:info@bccpakistan.com">
                <button className="bg-[#F5F5F5] text-[#79ba44] font-bold py-4 px-8 rounded">
                  Contact Us
                </button>
              </Link>
            </div>
          </div>
          <Footer />
        </div>
      ) : title == "freeblack" ? (
        <div className="bg-[#F5F5F5] min-h-screen pt-4 md:pt-20 ">
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              UP-001A Ecosole Flash Black
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Quick-drying black ink designed for efficient printing
                  processes and high-speed production applications.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              BR-001 Ecosole Jet Black
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  A deep black ink designed for high-quality printing, offering
                  rich color saturation and excellent contrast in printed
                  materials.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              PF-001 Ecosole Panther Black
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  A deep black ink designed for high-quality printing, offering
                  rich color saturation and excellent contrast in
                  printed materials.
                </p>
              </div>
            </div>
          </div>

          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12 mb-16">
            <div className="flex justify-center items-center mt-10">
              <Link to="mailto:info@bccpakistan.com">
                <button className="bg-[#F5F5F5] text-[#79ba44] font-bold py-4 px-8 rounded">
                  Contact Us
                </button>
              </Link>
            </div>
          </div>
          <Footer />
        </div>
      ) : title == "freeshades" ? (
        <div className="bg-[#F5F5F5] min-h-screen pt-4 md:pt-20 ">
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              PF-165 Ecosole Pink
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Silicone surface additive for solvent-borne coating systems
                  with a medium reduction of surface tension and a medium
                  increase of surface slip.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              PF-285 Ecosole Alpha Green
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Silicone surface additive for solvent-borne coating systems
                  with a medium reduction of surface tension and a medium
                  increase of surface slip.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              PF-167 Ecosole Brinjal Violet
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Silicone surface additive for solvent-borne coating systems
                  with a medium reduction of surface tension and a medium
                  increase of surface slip.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              PF-134A Ecosole Dark Red
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  A rich and deep red ink designed for high-quality printing
                  applications, providing vibrant and long-lasting color
                  results.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              PF-043 Ecosole Golden Yellow
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  A vivid and Golden yellow ink formulated for high-quality
                  printing, offering vibrant and eye-catching coloration in
                  printed materials.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              PF-045 Ecosole Lemon Yellow
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  A vivid and Lemon yellow ink formulated for high-quality
                  printing, offering vibrant and eye-catching coloration in
                  printed materials.
                </p>
              </div>
            </div>
          </div>{" "}
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              PF-166 Ecosole Magenta
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  A vivid and Magenta ink formulated for high-quality printing,
                  offering vibrant and eye-catching coloration in
                  printed materials.
                </p>
              </div>
            </div>
          </div>{" "}
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              PF-101 Ecosole Malta Orange
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  A vivid and Malta Orange ink formulated for high-quality
                  printing, offering vibrant and eye-catching coloration in
                  printed materials.
                </p>
              </div>
            </div>
          </div>{" "}
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              PF-207 Ecosole Navy Blue
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  A vivid and Navy Blue ink formulated for high-quality
                  printing, offering vibrant and eye-catching coloration in
                  printed materials.
                </p>
              </div>
            </div>
          </div>{" "}
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              PF-206 Ecosole Royal Blue
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  A vivid and Royal Blue ink formulated for high-quality
                  printing, offering vibrant and eye-catching coloration in
                  printed materials.
                </p>
              </div>
            </div>
          </div>{" "}
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              PF-208 Ecosole Turquoise Blue
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  A vivid and Turquoise Blue ink formulated for high-quality
                  printing, offering vibrant and eye-catching coloration in
                  printed materials.
                </p>
              </div>
            </div>
          </div>{" "}
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              PF-134 Ecosole National Red
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  A vivid and National Red ink formulated for high-quality
                  printing, offering vibrant and eye-catching coloration in
                  printed materials.
                </p>
              </div>
            </div>
          </div>{" "}
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              UP-285 Ecosole Flash Green
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  A vivid and Flash Green ink formulated for high-quality
                  printing, offering vibrant and eye-catching coloration in
                  printed materials.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              UP-045A Ecosole Flash Lemon Yellow
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  A vivid and Flash Lemon yellow ink formulated for high-quality
                  printing, offering vibrant and eye-catching coloration in
                  printed materials.
                </p>
              </div>
            </div>
          </div>{" "}
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              UP-207A Ecosole Flash Navy Blue
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  A vivid and Flash Navy Blue ink formulated for high-quality
                  printing, offering vibrant and eye-catching coloration in
                  printed materials.
                </p>
              </div>
            </div>
          </div>{" "}
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              UP-101 Ecosole Flash Orange
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  A vivid and Flash Orange ink formulated for high-quality
                  printing, offering vibrant and eye-catching coloration in
                  printed materials.
                </p>
              </div>
            </div>
          </div>{" "}
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              UP-165A Ecosole Flash Pink
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  A vivid and Flash Pink ink formulated for high-quality
                  printing, offering vibrant and eye-catching coloration in
                  printed materials.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              UP-134A Ecosole Flash Red
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  A vivid and Flash Red ink formulated for high-quality
                  printing, offering vibrant and eye-catching coloration in
                  printed materials.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              UP-206A Ecosole Flash Royal Blue
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  A vivid and Flash Royal Blue ink formulated for high-quality
                  printing, offering vibrant and eye-catching coloration in
                  printed materials.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12 mb-16">
            <div className="flex justify-center items-center mt-10">
              <Link to="mailto:info@bccpakistan.com">
                <button className="bg-[#F5F5F5] text-[#79ba44] font-bold py-4 px-8 rounded">
                  Contact Us
                </button>
              </Link>
            </div>
          </div>
          <Footer />
        </div>
      ) : title == "metalics" ? (
        <div className="bg-[#F5F5F5] min-h-screen pt-4 md:pt-20 ">
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              PF-477 Ecosole Metallic Gold
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  A lustrous gold ink formulated for high-quality printing,
                  providing metallic effects with a luxurious finish.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              PF-476 Ecosole Metallic Silver
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  A lustrous Silver ink formulated for high-quality printing,
                  providing metallic effects with a luxurious finish.
                </p>
              </div>
            </div>
          </div>

          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12 mb-16">
            <div className="flex justify-center items-center mt-10">
              <Link to="mailto:info@bccpakistan.com">
                <button className="bg-[#F5F5F5] text-[#79ba44] font-bold py-4 px-8 rounded">
                  Contact Us
                </button>
              </Link>
            </div>
          </div>
          <Footer />
        </div>
      ) : title == "additives" ? (
        <div className="bg-[#F5F5F5] min-h-screen pt-4 md:pt-20 ">
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              PF-003 Ecosole No Fell Adhesive
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  An ink designed without a noticeable hand feel, ideal for
                  achieving a smooth and comfortable texture in printed
                  materials.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              PF-4077 Ecosole Oil Puff Adhesive Base
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Ink specially formulated to create raised or puffed effects
                  when printed and dried, enhancing tactile and visual appeal.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              A-007 Paper Cotting
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  A paper coated with a heat-activated adhesive used in heat
                  transfer printing processes for applying designs onto
                  surfaces.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              A-006A Bright Screen Emulsion
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  A light-sensitive coating applied to screens for screen
                  printing, facilitating the transfer of designs onto various
                  surfaces with clarity.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              ACA-004 Anti-Choking Additive
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  A substance added to ink formulations to prevent clogging or
                  blocking of printing equipment during application processes.
                </p>
              </div>
            </div>
          </div>

          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12 mb-16">
            <div className="flex justify-center items-center mt-10">
              <Link to="mailto:info@bccpakistan.com">
                <button className="bg-[#F5F5F5] text-[#79ba44] font-bold py-4 px-8 rounded">
                  Contact Us
                </button>
              </Link>
            </div>
          </div>
          <Footer />
        </div>
      ) : title == "Anti" ? (
        <div className="bg-[#F5F5F5] min-h-screen pt-4 md:pt-20 ">
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              PF-ATMG Ecosole Anti-Migration Grey
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Ink designed to prevent color migration, ensuring precise and
                  stable printing results with a grey hue.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              PF-ATMG Ecosole Anti-Migration White
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Ink designed to prevent color migration, ensuring precise and
                  stable printing results with a White hue.
                </p>
              </div>
            </div>
          </div>

          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12 mb-16">
            <div className="flex justify-center items-center mt-10">
              <Link to="mailto:info@bccpakistan.com">
                <button className="bg-[#F5F5F5] text-[#79ba44] font-bold py-4 px-8 rounded">
                  Contact Us
                </button>
              </Link>
            </div>
          </div>
          <Footer />
        </div>
      ) : title == "transfersheet" ? (
        <div className=" min-h-screen pt-4 md:pt-4  md:mx-20 my-20">

        <div className="bg-[#79ba44]     p-4 md:p-12">
          <p className="text-2xl md:text-4xl lg:text-5xl  mb-4 text-white">
          Heat Transfer Sheets
          </p>

          <div className="flex flex-col md:flex-row pt-8">
            <div className="md:w-1/3 pr-0 md:pr-8">
              <img
                src={heat}
                className="w-full h-[400]"
                alt="Water base ink 
                            "
              />
            </div>
            <div className="md:w-1/2 md:ml-4 mt-4 md:mt-0">
              <p className="text-white md:text-lg lg:text-xl">Heat transfer sheets are flexible substrates used in heat transfer printing to carry printed designs from a carrier to a substrate through the application of heat and pressure.</p>
            </div>
          </div>
          <div className="bg-[#79ba44]   pt-4 md:pt-12">
          <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
            PET-168 Pet Sheet 19*24
          </p>
          <div className="flex flex-col md:flex-row pt-2">
            <div className="md:w-full mt-1 md:mt-1">
              <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                A PET sheet is a transparent, durable plastic sheet made from
                polyethylene terephthalate, commonly used for packaging,
                printing, and industrial applications.
              </p>
            </div>
          </div>
        </div>
        <div className="bg-[#79ba44]  pt-4 md:pt-12">
          <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
            S-003 Transfer Sheet Matt 20*30
          </p>
          <div className="flex flex-col md:flex-row pt-2">
            <div className="md:w-full mt-1 md:mt-1">
              <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                A transfer sheet matt is a matte-finish substrate used in
                transfer printing processes to apply designs onto surfaces
                with a non-glossy appearance.
              </p>
            </div>
          </div>
        </div>
        <div className="bg-[#79ba44]   pt-4 md:pt-12">
          <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
            S-005 Transfer Sheet White 10*10@113GM
          </p>
          <div className="flex flex-col md:flex-row pt-2">
            <div className="md:w-full mt-1 md:mt-1">
              <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                A heat transfer sheet is a available Different size and thin,
                flexible substrate used in heat transfer printing to carry
                printed designs from a carrier to a substrate, such as fabric
                or plastic, through the application of heat and pressure.
              </p>
            </div>
          </div>
        </div>{" "}
        <div className="bg-[#79ba44]   pt-4 md:pt-12">
          <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
            TR-S002 Transfer Sheet White 10*15@113G
          </p>
          <div className="flex flex-col md:flex-row pt-2">
            <div className="md:w-full mt-1 md:mt-1">
              <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                A heat transfer sheet is a available Different size and thin,
                flexible substrate used in heat transfer printing to carry
                printed designs from a carrier to a substrate, such as fabric
                or plastic, through the application of heat and pressure.
              </p>
            </div>
          </div>
        </div>{" "}
        <div className="bg-[#79ba44]  pt-4 md:pt-12">
          <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
            S-007 Transfer Sheet White 10*15@128GM
          </p>
          <div className="flex flex-col md:flex-row pt-2">
            <div className="md:w-full mt-1 md:mt-1">
              <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                A heat transfer sheet is a available Different size and thin,
                flexible substrate used in heat transfer printing to carry
                printed designs from a carrier to a substrate, such as fabric
                or plastic, through the application of heat and pressure.
              </p>
            </div>
          </div>
        </div>{" "}
        <div className="bg-[#79ba44]  pt-4 md:pt-12">
          <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
            TR-S011 Transfer Sheet White 10*15@90-GM
          </p>
          <div className="flex flex-col md:flex-row pt-2">
            <div className="md:w-full mt-1 md:mt-1">
              <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                A heat transfer sheet is a available Different size and thin,
                flexible substrate used in heat transfer printing to carry
                printed designs from a carrier to a substrate, such as fabric
                or plastic, through the application of heat and pressure.
              </p>
            </div>
          </div>
        </div>
        <div className="bg-[#79ba44]   pt-4 md:pt-12">
          <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
            TR-S012 Transfer Sheet White 11*18@90-GM
          </p>
          <div className="flex flex-col md:flex-row pt-2">
            <div className="md:w-full mt-1 md:mt-1">
              <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                A heat transfer sheet is a available Different size and thin,
                flexible substrate used in heat transfer printing to carry
                printed designs from a carrier to a substrate, such as fabric
                or plastic, through the application of heat and pressure.
              </p>
            </div>
          </div>
        </div>
        <div className="bg-[#79ba44]  pt-4 md:pt-12">
          <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
            TR-S0161 Transfer Sheet White 12*18@113 GM
          </p>
          <div className="flex flex-col md:flex-row pt-2">
            <div className="md:w-full mt-1 md:mt-1">
              <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                A heat transfer sheet is a available Different size and thin,
                flexible substrate used in heat transfer printing to carry
                printed designs from a carrier to a substrate, such as fabric
                or plastic, through the application of heat and pressure.
              </p>
            </div>
          </div>
        </div>
        <div className="bg-[#79ba44]  pt-4 md:pt-12">
          <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
            TR-S001A Transfer Sheet White 20*30 113G
          </p>
          <div className="flex flex-col md:flex-row pt-2">
            <div className="md:w-full mt-1 md:mt-1">
              <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                A heat transfer sheet is a available Different size and thin,
                flexible substrate used in heat transfer printing to carry
                printed designs from a carrier to a substrate, such as fabric
                or plastic, through the application of heat and pressure.
              </p>
            </div>
          </div>
        </div>
        <div className="bg-[#79ba44]  pt-4 md:pt-12">
          <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
            TR-S001 Transfer Sheet White 20*30 128G
          </p>
          <div className="flex flex-col md:flex-row pt-2">
            <div className="md:w-full mt-1 md:mt-1">
              <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                A heat transfer sheet is a available Different size and thin,
                flexible substrate used in heat transfer printing to carry
                printed designs from a carrier to a substrate, such as fabric
                or plastic, through the application of heat and pressure.
              </p>
            </div>
          </div>
        </div>
        <div className="bg-[#79ba44]  pt-4 md:pt-12">
          <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
            S-006 Transfer Sheet White Mate 20*30
          </p>
          <div className="flex flex-col md:flex-row pt-2">
            <div className="md:w-full mt-1 md:mt-1">
              <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                A heat transfer sheet is a available Different size and thin,
                flexible substrate used in heat transfer printing to carry
                printed designs from a carrier to a substrate, such as fabric
                or plastic, through the application of heat and pressure.
              </p>
            </div>
          </div>
        </div>
        <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12 mb-16">
        <div className="flex justify-center items-center mt-10">
          <Link to="mailto:info@bccpakistan.com">
            <button className="bg-[#F5F5F5] text-[#79ba44] font-bold py-4 px-8 rounded">
              Contact Us
            </button>
          </Link>
        </div>
      </div>
          </div>
        
         
          <Footer />
        </div>
     
      ) : title == "Powder" ? (
        <div className="bg-[#F5F5F5] min-h-screen pt-4 md:pt-20 ">
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              T-004 Ecosole Transfer Powder Super Fine
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Super fine transfer powder is an ultra-fine particulate
                  substance used in transfer printing processes, enabling
                  high-resolution and precise image transfers onto various
                  substrates with exceptional detail.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              T-001A Ecosole Transfer Powder 90-F
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  Transfer Powder 90-F is a specialized powder used in transfer
                  printing processes, engineered for high-quality image
                  transfers onto textiles and other substrates with enhanced
                  clarity and detail.
                </p>
              </div>
            </div>
          </div>{" "}
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12 mb-16">
            <div className="flex justify-center items-center mt-10">
              <Link to="mailto:info@bccpakistan.com">
                <button className="bg-[#F5F5F5] text-[#79ba44] font-bold py-4 px-8 rounded">
                  Contact Us
                </button>
              </Link>
            </div>
          </div>
          <Footer />
        </div>
      ) : title == "choking" ? (
        <div className="bg-[#F5F5F5] min-h-screen pt-4 md:pt-20 ">
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              ACA-004 Ecological Anti Choking Additive
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  "Anti-choking" refers to measures, techniques, or products
                  designed to prevent or alleviate choking incidents, commonly
                  related to printing Screen
                </p>
              </div>
            </div>
          </div>

          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12 mb-16">
            <div className="flex justify-center items-center mt-10">
              <Link to="mailto:info@bccpakistan.com">
                <button className="bg-[#F5F5F5] text-[#79ba44] font-bold py-4 px-8 rounded">
                  Contact Us
                </button>
              </Link>
            </div>
          </div>
          <Footer />
        </div>
      ) : title == "reducer" ? (
        <div className="bg-[#F5F5F5] min-h-screen pt-4 md:pt-20 ">
          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12">
            <p className="text-2xl md:text-3xl lg:text-4xl  mb-0 text-[white]">
              A-004D Ecosole Reducer Oil
            </p>
            <div className="flex flex-col md:flex-row pt-2">
              <div className="md:w-full mt-1 md:mt-1">
                <p className="text-base md:text-text-[18px] lg:text-[18px] text-[white]">
                  A reducer oil is a solvent or additive used to dilute the
                  concentration or viscosity of oil-based substances, often used
                  in painting, coatings, and lubrication applications.
                </p>
              </div>
            </div>
          </div>

          <div className="bg-[#79ba44] mx-4  md:mx-20  p-4 md:p-12 mb-16">
            <div className="flex justify-center items-center mt-10">
              <Link to="mailto:info@bccpakistan.com">
                <button className="bg-[#F5F5F5] text-[#79ba44] font-bold py-4 px-8 rounded">
                  Contact Us
                </button>
              </Link>
            </div>
          </div>
          <Footer />
        </div>
      ) : null}
    </div>
  );
}
