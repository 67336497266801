import React from "react";
import logo from "../assets/logo.png";
import {
  FiTwitter,
  FiFacebook,
  FiInstagram,
  FiYoutube,
  FiPhone,
  FiMail,
  FiGlobe,
} from "react-icons/fi";
import { Link, useLocation } from "react-router-dom";


const Footer = () => {
  return (
    <>
      <footer className="relative bg-white t-20 pb-10 lg:pt-[120px] lg:pb-20 px-0 md:px-10 ">
        <div className="container">
          <div className="flex flex-wrap ">
            <div className="w-full px-4 sm:w-2/3 lg:w-3/12">
              <div className="w-full mb-10">
                <a href="/#" className="mb-6 inline-block max-w-[160px]">
                  <img src={logo} alt="logo" className="max-w-full" />
                </a>
                <p className="text-base mb-7  text-[#79ba44] font-poppins">
                  Transforming industries with precision, versatility, and
                  vibrant, durable prints.
                </p>
                <p className="flex items-center text-sm font-medium text-dark mt-2">
                  <span className="mr-3 text-[#79ba44]">
                    <FiPhone size={16} /> {/* Use the phone icon */}
                  </span>
                  <a
                    href="https://api.whatsapp.com/send?phone=923007211816" // Replace with the WhatsApp number
                    className="text-[#79ba44] font-poppins  hover:text-black"
                  >
                    +92 300 7211816
                  </a>
                </p>
                <p className="flex items-center text-sm font-medium text-dark  pt-2">
                  <span className="mr-3 text-[#79ba44]">
                    <FiMail size={16} /> {/* Use the phone icon */}
                  </span>
                  <a
                    href="mailto:info@bccpakistan.com" // Replace with the email address
                    className="text-[#79ba44] font-poppins  hover:text-black"
                  >
                    info@bccpakistan.com
                  </a>
                </p>
                <p className="flex items-center text-sm font-medium text-dark pt-2">
                  <span className="mr-3 text-[#79ba44]">
                    <FiGlobe size={16} /> {/* Use the phone icon */}
                  </span>
                  <a
                    href="https://www.bccpakistan.com" // Replace with the website URL
                    className="text-[#79ba44] font-poppins  hover:text-black"
                  >
                    www.bccpakistan.com
                  </a>
                </p>
              </div>
            </div>

            <LinkGroup header="Products">
              <NavLink
                link="/detail?title=Water%20Based%20Inks&desc=Water%20based%20inks%20are%20defined%20as%20inks%20that%20use%20water%20as%20the%20main%20solvent%20for%20carrying%20pigment.%20They%20are%20made%20up%20mostly%20of%20acrylic%20resins%20or%20hybrid%20blends%20of%20acrylic%20resin%20and%20polyurethanes.%20%20%20%20%20%20%20%20%20%20&productId=undefined&image=/static/media/Bcc_Water%20based.2c82b544bf5708a7bd76.png"
                label="Water Based Inks"
              />
              <NavLink
                link="/detail?title=Plastisol%20Inks&desc=A%20plastisol%20is%20a%20colloidal%20dispension%20of%20small%20polymer%20particles%2C%20usually%20polyvinyl%20chloride%2C%20in%20a%20liquid%20plasticizer.%20When%20heated%20to%20around%20180%20°C%2C%20the%20plastic%20particles%20absorb%20the%20plasticizer%2C%20causing%20them%20to%20swell%20and%20fuse%20together%20forming%20a%20viscous%20gel.&productId=undefined&image=/static/media/Bcc_Plastisol%20Inks.cd23169916e05a3a2e33.png"
                label="Plastisol Inks"
              />
              <NavLink
                link="/detail?title=Silicone%20Inks&desc=Silicone%20ink%2C%20also%20called%20screen%20printing%20silicone%20or%20silicone-based%20ink%20is%20a%20kind%20of%20environmentally%20protective%20screen%20printing%20ink%20that%20is%20used%20for%20printing%20on%20fabric%20and%20leather.%20for%20example%2C%20on%20T-shirts%20or%20Shoes.%20It%20is%20mainly%20used%20in%20the%20textile%20printing%20industry.&productId=undefined&image=/static/media/Bcc_Silicon%20Inks.2e673deca916f10c4854.png"
                label="Silicone Inks"
              />
              <NavLink
                link="/detail?title=Auxiliaries&desc=%20Auxiliaries%20are%20additives%20that%20enhance%20ink%20properties%2C%20such%20as%20viscosity%2C%20adhesion%2C%20or%20drying%20time%2C%20optimizing%20printing%20processes.&productId=undefined&image=/static/media/Bcc_Auxiliaries.e0a31e74ff31a6455812.png"
                label="Auxiliaries"
              />
              <NavLink
                link="/detail?title=Eco%20Series&desc=A%20PVC%20free%20screen%20printing%20ink%20that%20works%20like%20plastisol%2C%20thereby%20offering%20the%20ease%20of%20use%20and%20many%20other%20advantages%20that%20come%20from%20plastisol%2C%20without%20any%20PVC%2C%20phthalates%2C%20or%20any%20other%20harmful%20substances.&productId=undefined&image=/static/media/Bcc_Eco.0b13925a3343429fcf3d.png"
                label="Eco Series"
              />
              <NavLink
                link="/detail?title=Special%20Effects&desc=Special%20Effects%20refer%20to%20unique%20visual%20enhancements%20like%20metallic%20finishes%2C%20holography%2C%20or%20fluorescence%2C%20elevating%20print%20aesthetics.&productId=undefined&image=/static/media/Bcc_Special%20Effets.d4b953e082e2988ffc89.png"
                label="Special Effects"
              />
              <NavLink
                link="/detail?title=Fluorescent%20Inks&desc=%20Fluorescent%20inks%20are%20pigmented%20printing%20inks%20that%20emit%20vibrant%2C%20highly%20visible%20colors%20under%20ultraviolet%20(UV)%20light%2C%20commonly%20used%20for%20attention-grabbing%20signage%20and%20promotional%20materials.&productId=undefined&image=/static/media/Bcc_Fluorescent%20Inks.cde78584d6479c4ae9cf.png"
                label="Fluorescent Inks"
              />
            </LinkGroup>

            <LinkGroup header={"Products"}>

              <NavLink
                link="/detail/product?title=processcmk"
                label="Process CMYK Inks"
              />
              <NavLink
                link="/detail/product?title=discharge"
                label="Discharge Inks"
              />
              <NavLink
                link="/detail/product?title=inks"
                label="Ink additives"
              />
              <NavLink
                link="/detail?title=Solvent%20based%20ink&desc=Solvent-based%20ink%20is%20a%20type%20of%20ink%20formulation%20where%20solvents%20serve%20as%20the%20primary%20carrier%2C%20evaporating%20upon%20drying%2C%20commonly%20used%20in%20printing%20for%20its%20fast-drying%20properties%20and%20versatility%20on%20various%20substrates.&productId=undefined&image=/static/media/Bcc_Solvent.f3cbac5d667495d22169.png"
                label="Solvent based ink"
              />
              <NavLink
                link="/detail?title=Heat%20Transfer%20Powders&desc=Heat%20transfer%20powders%20are%20fine%20particles%20used%20in%20transfer%20printing%20processes%2C%20activated%20by%20heat%20to%20adhere%20designs%20onto%20substrates%20such%20as%20textiles%20or%20ceramics.%20%20%20%20%20%20%20%20%20%20%20%20&productId=undefined&image=/static/media/Bcc_Transfer%20Powder.709176426c6d9e70aee6.png"
                label="Heat Transfer Powders"
              />
              <NavLink
                link="/detail/product?title=transfersheet"
                label="Heat Transfer Sheets"
              />
            </LinkGroup>

            <LinkGroup header="Company">
              <NavLink link="/detailCompany" label="Company & Information" />
              <NavLink link="/#" label=" Management" />
            </LinkGroup>

            <div className="w-full px-4 sm:w-1/2 lg:w-3/12">
              <div className="w-full mb-10">
                <h4 className="text-lg font-semibold mb-9 text-white font-poppins mt-0  md:mt-7">
                  Follow us On
                </h4>
                <div className="flex items-center mb-6">
                  <a
                    href="/#"
                    className="mr-3 flex h-8 w-8 items-center justify-center rounded-full border bg-[white] border-[#E5E5E5] text-black hover:border-primary  hover:bg-[#79ba44] hover:text-black hover:text-white sm:mr-4 lg:mr-3 xl:mr-4"
                  >
                    <FiInstagram size={16} />{" "}
                    {/* Use the filled Twitter icon */}
                  </a>

                  <a
                    href="/#"
                    className="mr-3 flex h-8 w-8 items-center justify-center rounded-full border bg-[white] border-[#E5E5E5] text-black hover:border-primary  hover:bg-[#79ba44]  sm:mr-4 lg:mr-3 xl:mr-4 hover:text-white"
                  >
                    <FiFacebook size={16} /> {/* Use the filled Twitter icon */}
                  </a>
                </div>
                <p className="text-base text-[#79ba44] font-poppins">
                  {" "}
                  © Copyright {new Date().getFullYear()} BCC . All Rights
                  Reserved .
                </p>
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </footer>
    </>
  );
};

export default Footer;

const LinkGroup = ({ children, header }) => {
  return (
    <>
      <div className="w-full px-4 sm:w-1/2 lg:w-2/12 mt-0  md:mt-7">
        <div className="w-full mb-20">
          <h4 className="text-lg font-semibold mb-9 text-[#79ba44] font-poppins ">
            {" "}
            {header ? header : ""}{" "}
          </h4>
          <ul >{children}</ul>
        </div>
      </div>
    </>
  );
};

const NavLink = ({ link, label }) => {
  const handleClick = ()=>{
    window.scrollTo(0, 0);
  }
  return (
    <div>
      <li>
        <Link
        onClick={handleClick}
          to={link}
          className={`inline-block mb-2 text-base leading-loose text-[#79ba44] hover:text-black text-[12px] font-poppins`}
        >
          {label}
        </Link>
      </li>
    </div>
  );
};
